type PublicEnv = "local" | "stage" | "production";
export const BRANCH_ID = "62de2c4bc4f903010a3b0da0";
export const COUNTRY = "Czech Republic";

const configs: Record<PublicEnv, { API_HOST: string }> = {
  local: {
    API_HOST: "api.test.nutritionpro.eu/api/",
  },
  stage: {
    API_HOST: "api.stage.nutritionpro.eu/api/",
  },
  production: {
    API_HOST: "api.nutritionpro.eu/api/",
  },
};
export const { API_HOST } = configs["stage"];
