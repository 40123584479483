export const base = {
  "src.components.routes.pages.Root.title": "NutritionPro",
  "src.components.routes.pages.BackBoxes.title": "NutritionPro",
  "error.date.required": "Date je povinný",
  "error.paymentMethod.required": "Payment method je vyžadováno.",
  "src.components.atoms.PricePerDay.title": "Price per menu",
  "src.components.atoms.PricePerDay.description": "(bez dopravy)",
  "src.components.atoms.PricePerDay.from": "from",
  "src.components.atoms.PricePerDay.symbol": "{price} CZK",
  "src.components.molecules.PriceCard,symbol": "{price} CZK",
  "src.components.routes.pages.DietBrno.title": "Brno",
  "src.components.molecules.PriceCard.title": "Order price",
  "src.components.molecules.PriceCard.delete": "Remove",
  "src.components.molecules.PriceCard.btn": "Continue",
  "error.companyName.required": "Zadejte název společnosti",
  "error.companyAddress.required": "Zadejte fakturační adresu",
  "error.regNumber.required": "Zadejte IČO společnosti",
  "error.vatNumber.required": "Zadejte DIČ společnosti",
  "error.polygon.required": "Zadejte city společnosti",
  "error.pickUpPoint.required": "Vaší odberné místo",
  "error.addressCity.required": "Zadejte address společnosti",
  "error.zip.required": "Zadejte ZIP společnosti",
  "src.components.molecules.PriceCard.promo": "Promo code „{promo}“",
  "src.components.routes.pages.DietHradec.title": "Hradec Kralove",
  "src.components.routes.pages.DietJablonec.title": "Jablonec and Nisou",
  "src.components.routes.pages.DietKladno.title": "Kladno",
  "src.components.routes.pages.DietSredniCechi.title": "ve Středních Čechách",

  "src.components.routes.pages.DietLiberec.title": " Liberec",
  "src.components.routes.pages.DietMlda.title": "Mlda Boleslav",
  "src.components.organisms.Footer.partner.btn": "Become a partner",
  "src.components.organisms.ThankYouModal.succsesTitle":
    "Your request was successfully received",
  "src.components.organisms.ThankYouModal.failTitle":
    "Something went wrong. Please try again",
  "src.components.organisms.GiftVoucherModal.description":
    "A gift voucher from NutritionPro is a gift that everyone will appreciate",
  "src.components.atoms.OnlineProgramCard.btn.default": "Vybrat",
  "src.components.organisms.GiftVoucherModal.titleFirst": "Make yours happy",
  "src.components.organisms.GiftVoucherModal.titleSecond": "the nearest",
  "src.components.organisms.ThankYouModal.btn": "Back to the main page",

  "src.components.molecules.DeliveryInfo.title": "There is always traffic",
  "src.components.molecules.DeliveryInfo.link": "for free",
  "src.components.molecules.OrderRightCard.jidel": "{jidel} chodové menu",
  "src.components.molecules.PromoCodeLink.promoCode": "Enter promo code",
  "src.components.molecules.OrderRightCard.btn": "Objednat online",
  "src.components.molecules.OrderRightCard.pocrovat": "Pokračovat",
  "src.components.routes.pages.DietParadubice.title": "Pardubice",
  "src.components.routes.pages.DietPlzen.title": "Plzen",
  "src.components.routes.pages.DietPraha.title": "Prague and surroundings",
  "src.components.routes.pages.Gdpr.title": "Gdpr",
  "src.components.organisms.ContactForm.Checkbox.personalData.title":
    "I agree to the processing",
  "src.components.organisms.ContactForm.Checkbox.personalData.link":
    " personal data",
  "src.components.molecules.CallMeCard.title":
    "Do you need advice on choosing?",
  "src.components.molecules.CallMeCard.description": "Call us.",
  "src.components.organisms.ProgramBlock.titleFirst": "Choose ",
  "src.components.organisms.ProgramBlock.titleSecond":
    " jeden z našich programů krabičkových diet",
  "src.components.organisms.ProgramBlock.description":
    "Enjoy a varied palette of flavors without limits. Within all programs, you now have the option to change courses at will.",
  "src.components.organisms.ContactForm.Checkbox.terms.title":
    "Consent to the processing of personal data for marketing purposes",
  "src.components.atoms.PricePerDay.dropava": "Cena za menu",
  "src.components.atoms.PricePerDay.dropavaDescription": "(bez dopravy)",
  "src.components.molecules.OnlineOrderFirstStepSidebar.promoCode":
    "Máš promokód?",
  "src.components.molecules.OnlineOrderFirstStepSidebar.order":
    "Můžeš ho vyplnit na konci objednávky.",
  "src.components.routes.pages.NotFound.title": "NotFound",
  "src.components.routes.pages.OnlineOrder.title": "OnlineOrder",
  "src.components.organisms.forms.redBtn": "I am interested in",
  "src.components.organisms.forms.GiftVoucherForm.btn": "Send",
  "src.components.organisms.forms.GiftVoucherForm.title":
    "Leave us a contact, we will contact you and help you choose a suitable program",
  "src.components.routes.pages.Terms.title": "General Terms and Conditions",
  "src.components.atoms.GiftVoucher.title": "Gift voucher",
  "src.components.atoms.WorkingHours.days": "Mon - Fri",
  "src.components.molecules.Menu.count": "Vacant positions",
  "src.components.atoms.Copyright.text":
    "Copyright ©{currentYear} NutritionPro s.r.o",
  "src.components.organisms.ProgramBlock.meat.title": "NutritionPro Classic",
  "src.components.organisms.ProgramBlock.meat.description":
    "Our classic representing a perfect symbiosis of delicious flavors and optimal nutrition.",
  "src.components.organisms.ProgramBlock.light.title": "NutritionPro Light",
  "src.components.organisms.ProgramBlock.vege.description":
    "Are you a vegetarian or just want to take a break from meat? Then this menu is for you, because it does not contain meat, including fish.",
  "src.components.organisms.ProgramBlock.vege.title": "NutritionPro Vege",
  "src.components.organisms.ProgramBlock.light.description":
    "A light menu without snacks at a great price, whether you don't like snacks or want to fill them yourself.",
  "src.components.organisms.ProgramBlock.fish.title": "Menu without fish",
  "src.components.organisms.ProgramBlock.fish.description":
    "Fish is not for everyone, that's why this menu does not include fish or fish products.",
  "src.components.organisms.ProgramBlock.combo.title": "NutritionPro combo",
  "src.components.organisms.ProgramBlock.combo.description":
    "This is a two-course menu (lunch and dinner), which you can choose according to your preferences.",
  "src.components.molecules.SocialMedia": "Follow us on social networks:",
  "src.components.organisms.Footer.location.title": "Address",
  "src.components.organisms.Footer.phone.title": "Phone",
  "src.components.organisms.Footer.email.title": "E-mail",
  "src.components.organisms.Footer.diet": "Top rated box diet.",
  "src.components.organisms.Footer.links": "Links",
  "src.components.organisms.Footer.program": "Programs",
  "src.components.molecules.ProgramCard.jidel": "{jidel} meals",
  "src.components.molecules.ProgramCard.jidla": "{jidel} meal",
  "src.components.molecules.ProgramCard.link": "More information",
  "src.components.molecules.ProgramCard.price": "from {price} CZK",
  "src.components.atoms.OnlineProgramCard.price": "from {price} CZK",
  "src.components.atoms.OnlineProgramCard.btn.acive": "Vybráno",
  "src.components.atoms.OnlineProgramCard.day": "day",
  "src.components.molecules.ProgramCard.day": "day",
  "src.components.molecules.ProgramCard.orderBtn": "Order",
  "src.components.molecules.ProgramCard.modalbtn": "Consult",
  "src.components.organisms.Footer.menu": "Menu",
  "src.components.organisms.Footer.price": "Price",
  "src.components.organisms.Footer.career": "Career",
  "src.components.organisms.Footer.documents": "Documents",
  "src.components.organisms.Footer.personalData": "Handling of personal data",
  "src.components.organisms.Footer.terms": "Terms and Conditions",
  "src.components.organisms.Footer.cookies": "Rules for the use of cookies",
  "src.components.organisms.Footer.settings": "Open cookie settings",
  "src.components.organisms.Footer.media": "For media, partners and companies",
  "src.components.organisms.Footer.partner": "Email: {email}",
  "src.components.organisms.DesktopHeader.price": "Calculate the price",
  "src.components.organisms.DesktopHeader.career": "Kariéra",
  "src.components.organisms.MobileHeader.career": "Kariéra",
  "src.components.organisms.DesktopHeader.blog": "Blog",
  "src.components.molecules.DeliveryItem.razvoz": "Rozvoz",
  "src.components.molecules.DeliveryItem.praha": "Krabičková dieta Praha",
  "src.components.molecules.DeliveryItem.brno": "Krabičková dieta Brno",
  "src.components.molecules.DeliveryItem.plzen": "Krabičková dieta Plzeň",
  "src.components.molecules.DeliveryItem.mlda":
    "Krabičková dieta Mladá Boleslav",
  "src.components.molecules.DeliveryItem.liberec": "Krabičková dieta Liberec",
  "src.components.molecules.DeliveryItem.hradec":
    "Krabičková dieta Hradec Králové",
  "src.components.molecules.DeliveryItem.paradubice":
    "Krabičková dieta Pardubice",
  "src.components.molecules.DeliveryItem.sredniCech":
    "Krabičková dieta Střední Čechy",
  "src.components.molecules.DeliveryItem.jablonec": "Krabičková dieta Jablonec",
  "src.components.organisms.MobileHeader.program": "Diet programs",
  "src.components.organisms.MobileHeader.customerPortal": "Customer portal",
  "src.components.organisms.MobileHeader.menu": "Menu",
  "src.components.organisms.MobileHeader.price": "Price",
  "src.components.organisms.MobileHeader.blog": "Blog",
  "src.components.organisms.MultiOrder.titleFirst": "Joint order",
  "src.components.organisms.MultiOrder.titleSecond":
    "with a discount of up to 20%",
  "src.components.organisms.ComplateMenuView.day": "DAY",
  "src.components.organisms.ComplateMenu.price": "{count} CZK",
  "src.components.organisms.ComplateMenu.priceTitle": "Super price",
  "src.components.organisms.ComplateMenu.titleFirst": "Feel free to try it",
  "src.components.organisms.ComplateMenu.titleSecond":
    "krabičkovou dietu na 2 dny",
  "src.components.molecules.PhoneInput.label": "Your phone",
  "src.components.organisms.SpecialistBlock.arrowText":
    "{specialist} with a nutritionist is {price} of each program",
  "src.components.organisms.SpecialistBlock.arrowText.sepcialist":
    "Consultation",
  "src.components.organisms.SpecialistBlock.arrowText.price":
    "included in the price",
  "src.components.routes.pages.DietSredniCechi.subTitle":
    "Nejpřesnější a nejflexibilnější doprava",
  "src.components.organisms.SpecialistBlock.textSecond":
    "Do you have boxes but don't know how to eat at the weekend? Are you hesitating which drinking regime is suitable for packaging? Do you want to make sure what energy intake you need? Do you have other nutrition questions or just want to get started? If you don't know something, I'm here for you. And you will understand.",
  "src.components.organisms.SpecialistBlock.sepicalist.text":
    "Expert nutritionist",
  "src.components.organisms.SpecialistBlock.sepicalist.title":
    "Jitka Dokoupilova",
  "src.components.organisms.forms.BannerForm.name": "Name and surname",
  "src.components.organisms.forms.CallMeForm.name": "Name and surname",
  "src.components.organisms.ComplateMenu.description":
    "Leave us a contact, we will get back to you within two hours during working hours and we will arrange everything individually within 10 minutes.",
  "src.components.organisms.MultiOrder.discount":
    "Your first order for {count} is discounted",
  "src.components.organisms.MultiOrder.order":
    "Order boxes with your partner, family or colleagues and get a better price.",
  "src.components.organisms.HowWorks.titleFirst": "How ",
  "src.components.organisms.SpecialistBlock.textFirst":
    "I'm a {specialist} at NutritionPro and I just live by nutrition! I studied food analysis at VŠCHT and nutritional therapy at the 1st Faculty of Medicine. I have been advising clients for more than {years} and during that time have passed through my hands ver {count}.",
  "src.components.organisms.SpecialistBlock.specialist": "nutrition consultant",
  "src.components.organisms.SpecialistBlock.years": "15 years",
  "src.components.organisms.SpecialistBlock.count": "1500",

  "src.components.organisms.HowWorks.titleSecond":
    "funguje naše krabičková dieta?",
  "src.components.organisms.HowWorks.daily":
    "We will set your daily income according to your goals",
  "src.components.organisms.SpecialistBlock.titleFirst":
    "Always under supervision",
  "src.components.organisms.SpecialistBlock.titleSecond": "nutritionist",
  "src.components.organisms.HowWorks.algorithm":
    "A special algorithm will create a tailor-made menu",
  "src.components.organisms.HowWorks.cooking": "We will cook the food",
  "src.components.organisms.HowWorks.delivery":
    "We will deliver to your home or work",
  "src.components.organisms.HowWorks.goal":
    "We will then see to it that you achieve your goal",
  "src.components.organisms.Delivery.titleFirst":
    "The most accurate and flexible transport already ",
  "src.components.organisms.Delivery.titleSecond":
    " in 9 cities of the Czech Republic",
  "src.components.organisms.Delivery.titleSecondMob": " in 9 cities",
  "src.components.organisms.Delivery.titleSecondMobLast":
    "of the Czech Republic",
  "src.components.organisms.Delivery.description":
    "Check whether, we also deliver to you, or whether one of our 16 collection points would suit you.",
  "src.components.organisms.Delivery.praha": "Praha",
  "src.components.organisms.Delivery.brno": "Brno",
  "src.components.organisms.Delivery.plzen": "Plzen",
  "src.components.organisms.Delivery.liberec": "Liberec",
  "src.components.organisms.Delivery.hradec": "Hradec Kralove",
  "src.components.organisms.Delivery.paradubice": "Pardubice",
  "src.components.organisms.Delivery.jablonec": "Jablonec",
  "src.components.organisms.Delivery.kladno": "Střední Čechy",
  "src.components.organisms.Delivery.mlda": "Mlada Boleslav",
  "src.components.organisms.Delivery.praha.time": "2 hour window for delivery",
  "src.components.organisms.Delivery.brno.time":
    "Delivery in the evening between 18:00-23:00.",
  "src.components.organisms.Delivery.plzen.time": "3 hour window for delivery",
  "src.components.organisms.Delivery.liberec.time":
    "Delivery in the evening between 17:00-22:00",
  "src.components.organisms.Delivery.hradec.time":
    "Delivery in the evening between 16:30-22:00.",
  "src.components.organisms.Delivery.paradubice.time":
    "Delivery in the evening between 16:30-22:00.",
  "src.components.organisms.Delivery.kladno.time": "17:00 - 22:00",
  "src.components.organisms.Delivery.jablonec.time":
    "Delivery in the evening between 18:00-22:00",
  "src.components.organisms.Delivery.mlda.time":
    "Delivery in the evening between 16:00-22:00",
  "src.components.organisms.Delivery.praha.replace":
    "Change of time and place on the day of import until 10:00",
  "src.components.organisms.Delivery.brno.replace":
    "Change of time and place on the day of import until 10:00",
  "src.components.organisms.Delivery.plzen.replace":
    "Change of time and place on the day of import until 10:00",
  "src.components.organisms.Delivery.liberec.replace":
    "Change of time and place on the day of import until 10:00",
  "src.components.organisms.Delivery.hradec.replace":
    "Change of time and place on the day of import until 10:00",
  "src.components.organisms.Delivery.paradubice.replace":
    "Change of time and place on the day of import until 10:00",
  "src.components.organisms.Delivery.kladno.replace":
    "Change of time and place on the day of import until 10:00",
  "src.components.organisms.Delivery.jablonec.replace":
    "Change of time and place on the day of import until 10:00",
  "src.components.organisms.Delivery.mlda.replace":
    "Change of time and place on the day of import until 10:00",
  "src.components.organisms.Delivery.praha.point": "10 collection points",
  "src.components.molecules.DeliveryCard.delivery": "Free delvery",
  "src.components.organisms.HowCook.titleFirst": "Proč ",
  "src.components.organisms.HowCook.titleSecond": "NutritionPro?",
  "src.components.organisms.HowCook.titleSecond.praha":
    " krabičková dieta Praha?",
  "src.components.organisms.HowCook.description":
    "Take a look at the unique process of preparing our great meals",
  "src.components.organisms.HowCook.area.title": "Modern production area",
  "src.components.organisms.HowCook.area.descritpion":
    "We use the most modern technologies to process raw materials as gently as possible.",
  "src.components.organisms.HowCook.suppliers.title": "The best suppliers",
  "src.components.organisms.HowCook.suppliers.description":
    "All our suppliers are vetted over the long term.",
  "src.components.organisms.HowCook.ingredients.title":
    "Only the best quality ingredients",
  "src.components.organisms.Vege.titleFirst": "Vegetarian",
  "src.components.organisms.Vege.titleSecond": "menu",
  "src.components.organisms.Vege.list.title":
    "You can already enjoy our meat-free menu.",
  "src.components.organisms.Vege.list.description1":
    "A nutritionally balanced menu",
  "src.components.organisms.Vege.list.description2": "Sufficient protein",
  "src.components.organisms.Vege.list.description3": "Tasty and varied menu",
  "src.components.organisms.Vege.list.description4": "Premium ingredients",
  "src.components.organisms.Vege.form.title":
    "Our veggie boxes are already waiting for you.",
  "src.components.organisms.Vege.form.description":
    "Fill in your phone number and we'll get back to you.",
  "src.components.organisms.Vege.description":
    "Try it differently and taste our meat-free dishes!",
  "src.components.organisms.HowCook.ingredients.description":
    "We check each individual delivery to ensure that only the highest quality raw materials get processed.",
  "src.components.organisms.HowCook.chefs.title": "Experienced chefs",
  "src.components.organisms.HowCook.chefs.descripton":
    "Our chefs cook according to the latest trends and procedures, preparing up to 5,000 dishes for you every day.",
  "src.components.molecules.GoogleMark.text":
    "{count}+ positive customer reviews",

  "src.components.routes.pages.DietPraha.deliveryTitle":
    "{greenTitle} krabičková dieta v Praze",
  "src.components.routes.pages.DietPraha.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietBrno.deliveryTitle":
    "{greenTitle} krabičková dieta v Brně",
  "src.components.routes.pages.DietBrno.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietPlzen.deliveryTitle":
    "{greenTitle} krabičková dieta v Plzni",
  "src.components.routes.pages.DietPlzen.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietLiberec.deliveryTitle":
    "{greenTitle} krabičková dieta v Liberci",
  "src.components.routes.pages.DietLiberec.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietHradec.deliveryTitle":
    "{greenTitle} krabičková dieta v Hradci Králové",
  "src.components.routes.pages.DietHradec.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietHradecKralove.deliveryTitle":
    "{greenTitle} krabičková dieta v Hradci Králové",
  "src.components.routes.pages.DietHradecKralove.greenTitile":
    "Nejflexibilnejší",
  "src.components.routes.pages.DietParadubice.deliveryTitle":
    "{greenTitle} krabičková dieta v Pardubicích",
  "src.components.routes.pages.DietParadubice.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietSredniCechi.deliveryTitle":
    "{greenTitle} krabičková dieta ve Středních Čechách",
  "src.components.routes.pages.DietSredniCechi.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietJablonec.deliveryTitle":
    "{greenTitle} krabičková dieta v Jablonci nad Nisou",
  "src.components.routes.pages.DietJablonec.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietJablonecNisou.deliveryTitle":
    "{greenTitle} krabičková dieta v Jablonci nad Nisou",
  "src.components.routes.pages.DietJablonecNisou.greenTitile":
    "Nejflexibilnejší",
  "src.components.routes.pages.DietMldaBoleslav.deliveryTitle":
    "{greenTitle} krabičková dieta v Mladé Boleslavi",
  "src.components.routes.pages.DietMldaBoleslav.greenTitile":
    "Nejflexibilnejší",
  "src.components.routes.pages.DietMlda.deliveryTitle":
    "{greenTitle} krabičková dieta v Mladé Boleslavi",
  "src.components.routes.pages.DietMlda.greenTitile": "Nejflexibilnejší",
  "src.components.routes.pages.DietKladno.deliveryTitle":
    "{greenTitle} krabičková dieta v Kladno",
  "src.components.routes.pages.DietKladno.greenTitile": "Nejflexibilnejší",
  "src.components.molecules.Partners.text": "Official partners",
  "src.components.organisms.RateBlock.titleFirst":
    "{greenTitle} hodnocená krabičková dieta",
  "src.components.organisms.RateBlock.greenTitle": "Best ",
  "src.components.molecules.BenefitCard.textFirst": "Choose from ",
  "src.components.organisms.ReviewBlock.titleFirst": "Real customers ",
  "src.components.organisms.ReviewBlock.titleSecond":
    "they will say more than us.",
  "src.components.atoms.Review.seeMore": "Show all",
  "src.components.organisms.ReviewBlock.description":
    "We approach each customer individually to ensure the best possible service.",
  "src.components.molecules.BenefitCard.benefit": "4 meals ",
  "src.components.molecules.BenefitCard.textLast": "for each course.",
  "src.components.organisms.RateBlock.redBtn": "Mám zájem",
  "src.components.organisms.HowCook.redBtn": "Order online",
  "src.components.organisms.CheckDelivery.title":
    "Check if we deliver to you too.",
  "src.components.organisms.RateBlock.redBtnCity": "Vybrat program",

  "src.component.organisms.CheckDelivery.faild":
    "Sorry! We are not in this location yet.",
  "src.component.organisms.CheckDelivery.success": "We deliver to your address",
  "src.component.organisms.CheckDelivery.placenholder":
    "Fill in your street, postal code and city.",
  "src.components.molecules.AddressCheck.confirm": "Confirm",
  "src.components.organisms.Faq.titleFirst": "Frequently asked",
  "src.components.organisms.Faq.titleSecond": "questions",
  "src.components.organisms.Faq.menu.title": "Customized menu",
  "src.components.organisms.Faq.materials.title": "Raw materials",
  "src.components.organisms.Faq.delivery.title": "Delivery and payment",
  "src.components.organisms.Faq.consumption.title": "Method of consumption",
  "src.components.organisms.Faq.menu.subTitle1":
    "V čem je naše krabičková dieta jiná?",
  "src.components.organisms.Faq.menu.content1":
    "Naše {info} se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše {info} je navržena přesně tak, aby odpovídala vašim individuálním potřebám a cílům, což je něco, co běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.Faq.menu.subTitle2":
    "Kdo sestavuje jídelníček pro krabičkovou dietu?",
  "src.components.organisms.Faq.menu.content2":
    "Jídelníček pro naši {info} je sestavován naším speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že dosáhnete požadovaných výsledků.",
  "src.components.organisms.Faq.menu.subTitle3":
    "Jak vypadá jídelníček v rámci krabičkové diety?",
  "src.components.organisms.Faq.menu.content3":
    "Naše {info} je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, takže se naše jídla nikdy nepřejí. Podívejte se na {boldInfo}, který nabízíme.",
  "src.components.organisms.Faq.materials.subTitle1":
    "Jaké suroviny používáte pro krabičkovou dietu?",
  "src.components.organisms.Faq.materials.content1":
    "Pro naši {info} používáme pouze kvalitní a čerstvé suroviny od prověřených českých dodavatelů. Kvalita a čerstvost surovin je pro nás základem zdravého stravování.",
  "src.components.organisms.Faq.materials.subTitle2":
    "AJsou jídla v rámci krabičkové diety čerstvá?",
  "src.components.organisms.Faq.materials.content2":
    "Ano, všechna jídla v rámci naší {info} jsou připravována z čerstvých surovin, které kupujeme v den přípravy. Po uvaření jsou jídla šokově zchlazena a hermeticky zabalena, což zajišťuje jejich maximální čerstvost",
  "src.components.organisms.Faq.materials.subTitle3":
    "Kdy připravujete jídla pro krabičkovou dietu?",
  "src.components.organisms.Faq.materials.content3":
    "Příprava jídel pro naši {info} probíhá v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek, a ve čtvrtek na pátek a sobotu.",
  "src.components.organisms.Faq.materials.subTitle4":
    "Máte v nabídce i dezerty v rámci krabičkové diety?",
  "src.components.organisms.Faq.materials.content4":
    "Ano, naše {info} zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",
  "src.components.organisms.Faq.delivery.subTitle1":
    "Jak dlouho dopředu musím nahlásit vynechání dovozu krabičkové diety?",
  "src.components.organisms.Faq.client.title": "Zmeny a Zákaznická podpora",
  "src.components.organisms.Faq.delivery.content1":
    "Jakékoliv změny objednávky v rámci naší {info}, včetně pozastavení dovozu, je nutné nahlásit nejpozději do 11:00 tři pracovní dny před plánovaným doručením. Tato pravidla platí, protože pro každé vaření nakupujeme čerstvé suroviny na přesný počet zákazníků.",
  "src.components.organisms.Faq.delivery.subTitle2":
    "Mohu zrušit pouze jeden den v rámci krabičkové diety?",
  "src.components.organisms.Faq.delivery.content2":
    "Pokud se vaše objednávka {info} vztahuje na dva dny, je možné zrušit pouze celou dvoudenní objednávku. Zrušení pouze jednoho dne není možné.",
  "src.components.organisms.Faq.delivery.subTitle3":
    "Jak je stanovena cena krabičkové diety?",
  "src.components.organisms.Faq.delivery.content3":
    "Cena naší {info} je stanovena individuálně podle vašeho osobního stravovacího plánu a denního kalorického příjmu. Cena se může lišit v závislosti na vašich cílech, ať už se jedná o hubnutí, nabírání svalové hmoty nebo udržování váhy. Kalkulaci ceny si můžete nechat vypočítat v naší {boldInfo}.",
  "src.components.organisms.Faq.delivery.subTitle4":
    "Jak probíhá platba za krabičkovou dietu?",
  "src.components.organisms.Faq.delivery.content4":
    "Po dokončení objednávky naší {info} vám zašleme fakturu na váš e-mail. Můžete také zvolit platbu kartou.",
  "src.components.organisms.Faq.delivery.subTitle5":
    "How are you different from other box diets?",
  "src.components.organisms.Faq.delivery.content5":
    "We believe in an individual approach. Each of us is unique and each of us needs a different caloric intake if we want to reach our goals in a healthy way. The menu will look different for a 70 kg woman who just wants to improve her diet and maintain her weight, and a different one for a 90 kg man who wants to gain muscle mass or, on the contrary, is trying to lose weight. The menu is therefore adapted to the size of the body and goals, which is not taken into account by an ordinary box diet.",
  "src.components.organisms.Faq.delivery.subTitle6":
    "Do jakých měst rozvážíte krabičkovou dietu?",
  "src.components.organisms.Faq.delivery.content6":
    "Naši {info} rozvážíme po {prague}, {chech}, {kladno}, {plzen}, {liberec}, {mlda}, {jablonec}, {paradubic} a {hradec}.",
  "src.components.organisms.Faq.delivery.subTitle7":
    "Kdy doručujete jídla z krabičkové diety?",
  "src.components.organisms.Faq.delivery.content7":
    "Jídla z naší {info} doručujeme obden – v úterý, čtvrtek a neděli, vždy mezi 16:00 a 22:00 hodinou.",
  "src.components.organisms.Faq.delivery.subTitle8":
    "V kolik mi jídlo doručíte?",
  "src.components.organisms.Faq.delivery.content8":
    "Praha: Můžeš si vybrat konkrétní 2 hodinové okno pro doručení (17:00 - 22:00). Plzeň: Můžeš si vybrat ze 3 možností časových oken: 17:00 - 20:00, 18:00 - 21:00, 19:00 - 21:00. V ostatních městech rozvážíme ve večerních hodinách, a to konkrétně: Liberec: 17:00 - 22:00, Mladá Boleslav: 16:00 - 22:00, Jablonec nad Nisou: 18:00 - 22:00, Pardubice: 16:30 - 22:00, Hradec Králové: 16:30 - 22:00. Přesný čas dovozu bude upřesněn s řidičem v den dovozu.",
  "src.components.organisms.Faq.delivery.subTitle9":
    "Co když potřebuji změnit doručovací údaje pro krabičkovou dietu?",
  "src.components.organisms.Faq.delivery.content9":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 12:00.",
  "src.components.organisms.Faq.consumption.subTitle1":
    "Musí se jídlo z krabičkové diety ohřívat?",
  "src.components.organisms.Faq.consumption.content1":
    "Některá jídla z naší {info} jsou určena k ohřevu, jiná ke konzumaci za studena. Pro lepší chuť však doporučujeme jídla ohřát. Informace o tom, které jídlo je třeba ohřát, zjistíte po načtení čárového kódu na krabičce.",
  "src.components.organisms.Faq.consumption.subTitle2":
    "Jak ohřívat jídlo z krabičkové diety?",
  "src.components.organisms.Faq.consumption.content2":
    "Pokud ohříváte jídla z naší {info} v mikrovlnné troubě, doporučujeme propíchnout dírky do folie. Čas ohřevu se liší podle druhu jídla a velikosti porce, ale doporučujeme použít střední výkon (600–700 W) pro rovnoměrné prohřátí.",
  "src.components.organisms.Faq.consumption.subTitle3":
    "Jak skladovat jídla z krabičkové diety?",
  "src.components.organisms.Faq.consumption.content3":
    "Jídla z naší {info} doporučujeme skladovat v lednici, ale můžete je vzít s sebou i do práce nebo na cesty. Jídla vydrží několik hodin mimo lednici bez ztráty chuti nebo kvality.",
  "src.components.molecules.OnlineConfirmCard.price": "{price} CZK/day",
  "src.components.molecules.OnlineConfirmCard.jidel": "{jidel}сhodové menu",
  "src.components.atoms.ConfirmPrice.text": "Total price (incl. VAT)",
  "src.components.atoms.ConfirmPrice.price": "{price} CZK",
  "src.components.organisms.PaymentModal.successTitle":
    "Thank you, we have successfully received your payment.",
  "src.components.organisms.PaymentModal.failTitle":
    "The payment was not completed.",
  "src.components.organisms.PaymentModal.successText":
    "We are starting to process your order {number}. We have also sent you this confirmation by email.",
  "src.components.organisms.PaymentModal.failText":
    "We're sorry, but something went wrong and your payment wasn't completed.",
  "src.components.organisms.PaymentModal.succsesBtn": "Back to the main page",
  "src.components.organisms.PaymentModal.failBtn": "Try again",
  "src.components.organisms.PaymentModal.back": "Back to payment selection",
  "src.components.organisms.CookieModal.title":
    "To make NutritionPro work well for you",
  "error.phone.min": "The phone number must be at least 12 characters long",
  "src.components.organisms.CookieModal.description":
    "To make browsing our website as convenient as possible, we use cookies. Cookies serve us to improve our services and at the same time, thanks to them, we can better offer you content that may be interesting and useful for you.",
  "src.components.organisms.CookieModal.link": "More",
  "src.components.organisms.CookieModal.accept": "Accept everything",
  "src.components.organisms.CookieModal.reject": "Refuse",
  "error.phone.required": "Phone is required",
  "error.personal.required": "Field is required",
  "error.name.required": "Name is required",
  "error.email.required": "Email is required",
  "error.faktura.required": "Fakutra is required",
  "error.comment.required": "Filed is required",
  "src.components.routes.pages.ThankYouAffiliate.title": "Thank you affiliate",
  "src.components.routes.pages.ThankYou.title": "Thabk you",
  "src.components.organisms.MenuBlock.titleFirst": "What foods",
  "src.components.organisms.MenuBlock.titleSecond":
    "are they waiting for you with us?",
  "src.components.organisms.MenuBlock.tab5": "Friday",
  "src.components.organisms.MenuBlock.tab4": "Thursday",
  "src.components.organisms.MenuBlock.tab3": "Wednesday",
  "src.components.organisms.MenuBlock.tab2": "Tuesday",
  "src.components.organisms.MenuBlock.tab1": "Monday",
  "src.components.organisms.MneuBlock.food1.title":
    "Coconut French Toast with Maple Syrup and Grape Wine",
  "src.components.organisms.MneuBlock.food2.title":
    "Fruit salad with cashew nuts",
  "src.components.organisms.MneuBlock.food3.title":
    "Roast beef with honey-mustard sauce, potato wedges",
  "src.components.organisms.MneuBlock.food4.title":
    "Quiche with spinach leaves and Balkan cheese",
  "src.components.organisms.MneuBlock.food5.title":
    "Italian risotto with oyster mushrooms, white wine and parmesan",
  "src.components.organisms.MneuBlock.food6.title":
    "Oatmeal with coconut milk, blueberries and dark chocolate",
  "src.components.organisms.MneuBlock.food7.title":
    "Pear with nuts and chocolate",
  "src.components.organisms.MneuBlock.food8.title":
    "Chicken shashlik, baked potato slices, BBQ sauce",
  "src.components.organisms.MneuBlock.food9.title":
    "Turkey ham rolls on lettuce leaves with cashews and parmesan cheese",
  "src.components.organisms.MneuBlock.food10.title":
    "Tacos with Mexican beans, grated cheddar cheese, iceberg lettuce and tomatoes",
  "src.components.organisms.MneuBlock.food11.title":
    "Greek yogurt with wild berries and honey",
  "src.components.organisms.MneuBlock.food12.title":
    "Old Bohemian poppy pancakes with plum jam, cottage cheese and grated gingerbread",
  "src.components.organisms.MneuBlock.food13.title":
    "Chicken steak Caprese, baked potato slices",
  "src.components.organisms.MneuBlock.food14.title":
    "Huevos rancheros with chickpeas",
  "src.components.organisms.MneuBlock.food15.title":
    "Salmon with broccoli, spinach and tomatoes",
  "src.components.organisms.MneuBlock.food16.title":
    "Egg spread made from cottage cheese and chives, dark pastry",
  "src.components.organisms.MneuBlock.food17.title":
    "Cottage cheese dessert with blueberries",
  "src.components.organisms.MneuBlock.food18.title": "Lasagna Bolognese",
  "src.components.organisms.MneuBlock.food19.title":
    "Coconut pancakes with cream cheese and Provençal vegetables",
  "src.components.organisms.MneuBlock.food20.title":
    "Chicken medallions on thyme bulgur with baked carrot fries and grilled zucchini",
  "src.components.organisms.MneuBlock.food21.title":
    "Baked omelet with cheddar and parsley",
  "src.components.organisms.MneuBlock.food22.title": "Raw walnut-date balls",
  "src.components.organisms.MneuBlock.food23.title":
    "Indian Chicken Satay with peanut sauce and Basmati rice",
  "src.components.organisms.MneuBlock.food24.title":
    "Chicken and cream cheese sandwich",
  "src.components.organisms.MneuBlock.food25.title": "Vietnamese soup Pho Bo",
  "src.components.organisms.MneuBlock.breakfast": "Breakfast",
  "src.components.organisms.MneuBlock.snack1": "Snack 1",
  "src.components.organisms.MneuBlock.snack2": "Snack 2",
  "src.components.organisms.MneuBlock.lunch": "Lunch",
  "src.components.organisms.MneuBlock.dinner": "Dinner",
  "src.component.organism.BannerBlock.title": "Order your food boxes today.",
  "src.components.organisms.ReviewBlock.title1": "Adam Pechar",
  "src.components.organisms.ReviewBlock.description1":
    "One of the best investments I have made recently, I can only recommend it! Seamless collaboration, balanced diet and great taste. Best box diet I've tried so far. I feel great after the meal and look forward to the next course every time. An essential step for a healthy lifestyle. 👍 In one word, PERFECT",
  "src.components.organisms.ReviewBlock.title2": "Renata Vydrova",
  "src.components.organisms.ReviewBlock.description2":
    "Their food is very good. :) And what's more, I no longer have to worry about what I'm going to cook on which day. For me it's TOP!",
  "src.components.organisms.ReviewBlock.title3": "Anton Lancevic",
  "src.components.organisms.ReviewBlock.description3": "I can only recommend!",
  "src.components.organisms.ReviewBlock.title4": "Hana Cislerova",
  "src.components.organisms.ReviewBlock.description4":
    "The best boxes I've ever tried. There was also plenty of food, so I shared with my son and it was cheaper for us than shopping and cooking",
  "src.components.organisms.ReviewBlock.title5": "Margita Novotna",
  "src.components.organisms.ReviewBlock.description5":
    "Portions more than enough, very varied, tasty. The service is perfect, the appointment and other recommendations with a willing consultant without a mistake. I recommend.",
  "src.components.organisms.ReviewBlock.title6": "Kristyna Burdova",
  "src.components.organisms.ReviewBlock.description6":
    "The boxes are absolutely great, they saved me during home offices when I didn't have time to cook, let alone something healthy. Salesian Jitka helped me plan the optimal caloric intake so that I would not be hungry and at the same time lose a little weight. I was very pleasantly surprised that Jitka asked me about my eating and exercise habits and put together the boxes accordingly, which are very tasty. I recommend!",
  "src.components.organisms.ReviewBlock.title7": "Jan Vitek",
  "src.components.organisms.ReviewBlock.description7":
    "The fish with the veggies was amazing! 👌",
  "src.components.organisms.ReviewBlock.title8": "Jan Kriz",
  "src.components.organisms.ReviewBlock.description8":
    "Always excellent taste and appearance, exactly tailored for me, great service all around, perfect service for me and I am finally fed in such a way that I don't have to deal with finishing my meal anywhere else. In addition, during the covid situation, NutritionPro also solved the situation for people around me who can stay and eat at home when they do not feel safe to find and buy anything outside. So thumbs up and not only for this year, a clear choice. Thanks for her!!",
  "src.components.organisms.ReviewBlock.title9": "Lev Lyapeikov",
  "src.components.organisms.ReviewBlock.description9":
    "Great service! I have ordered twice for the stock. Couriers arrived on time, food quality is excellent. My wife at home with a 6 month old baby and there is no time for cooking. A diet of 2000 calories is enough for my wife all day and I take lunch with me for work. A very profitable solution, cheaper than buying products in the store + saving time. Today I made an order for 12 days (Pleasantly surprised by the low prices), for almost two weeks I’m free from shopping in groceries. Thank you so much for the yummy food and services!",
  "src.components.organisms.ReviewBlock.title10": "Nicole Ponce",
  "src.components.organisms.ReviewBlock.description10":
    "For someone that is a very picky eater, I would have to say that the meals from NutritionPro have exceeded my expectations and more. It makes my day so much easier to know that I have my meals prepared and ready everyday, straight to my door. I would highly recommend.",
  "src.components.organisms.ReviewBlock.title11": "Aneta Benova",
  "src.components.organisms.ReviewBlock.description11":
    "Very tasty and varied food :)",
  "src.components.organisms.ReviewBlock.title12": "Antoine Bourg",
  "src.components.organisms.ReviewBlock.description12":
    "Super satisfied, the recipes are simple yet diverse, and delightful. Feels like home cooking, and the results are seen very early on. Would recommend it to anyone looking for a tailored nutrition plan!!",
  "src.components.organisms.ReviewBlock.title13": "olina Moroz",
  "src.components.organisms.ReviewBlock.description13":
    "(Původní) Мне очень понравилось. Меню разнообразное и ,самое главное, вкусное. Советую! (Translation) I really liked it. The menu is varied and above all tasty. I recommend!",
  "src.components.organisms.ReviewBlock.title14": "Ondrej Stanek",
  "src.components.organisms.ReviewBlock.description14":
    "Excellent boxes. They don't repeat themselves very often, and you often get to taste exotic foods that you wouldn't normally come across. Portions great and balanced. I'm definitely not hungry and I'm losing weight naturally",
  "src.components.organisms.ReviewBlock.title15": "Tereza Zateckova",
  "src.components.organisms.ReviewBlock.description15":
    "I have been receiving the boxes for a long time and they still maintain their quality. The food is varied, fresh and the delivery is always accurate. The quality/price ratio corresponds. I recommend 👍",
  "src.components.organisms.ReviewBlock.title16": "Viktor Nyitray",
  "src.components.organisms.ReviewBlock.description16":
    "I am very satisfied with the boxes. It's not the classic food from a soup kitchen packed in boxes, but the food is really tasty! I recommend at least trying it :)",
  "src.components.organisms.ReviewBlock.title17": "Irena Rabochova",
  "src.components.organisms.ReviewBlock.description17":
    "The food is very tasty and balanced, made from fresh ingredients. Time saving and weight loss. The service is first class, especially the delivery.",
  "src.components.organisms.ReviewBlock.title18": "Lucie Bartosova",
  "src.components.organisms.ReviewBlock.description18":
    "The food is absolutely great, but there is one thing that is much better, they are the food importers, mainly one lady, she always makes my day, she is absolutely perfect",
  "src.components.organisms.ReviewBlock.title19": "Tereza Hofmanova",
  "src.components.organisms.ReviewBlock.description19":
    "Very good boxes, I like the variety of the food. For me, a clear time in the period when I don't have time to cook. I RECOMMEND!",
  "src.components.organisms.ReviewBlock.title20": "Zdenka Svobodova",
  "src.components.organisms.ReviewBlock.description20":
    "Very tasty food, very diverse in terms of ingredients. And you will also taste unusual food. The combination sometimes seemed strange to me (lentil with fish), but always really well prepared and, despite the initial strangeness, it harmonizes in taste. You can choose from sweet or salty snacks. I have a sweet one ;) and it fully satisfies those with a sweet tooth who want to lose a few kilos. I struggled internally with the small portions at first, but that was my previous gluttony. I got used to it in 3 days and after a month I can say that the portions are just right, varied and above all I like them. I don't really feel like I'm on a diet ;) and I'm looking forward to the food. Delivery is also great, 3 times a week even in the late hours and the lady who drives to me is very nice. Thank you and keep going ;)",
  "src.components.organisms.ReviewBlock.title21": "Linda Dlabacova",
  "src.components.organisms.ReviewBlock.description21": "New menu",
  "src.components.organisms.ReviewBlock.title22": "Maldonado David",
  "src.components.organisms.ReviewBlock.description22": "Super tasty food",
  "src.components.organisms.ReviewBlock.title23": "Matej Schultz",
  "src.components.organisms.ReviewBlock.description23":
    "Best value for money, you really helped me, thank you very much!!",
  "src.components.organisms.ReviewBlock.title24": "Klara Onderkova",
  "src.components.organisms.ReviewBlock.description24":
    "Always delivery on time and super service, thanks for the time saved nutrition pro!!",
  "src.components.organisms.ReviewBlock.title25": "Aneta peckova",
  "src.components.organisms.ReviewBlock.description25":
    "maybe the first similar service that did not disappoint me during the first week, on the contrary, great!!",
  "src.components.organisms.ReviewBlock.title26": "Gregory Pelec",
  "src.components.organisms.ReviewBlock.title27":
    "Its been a while since I started my plan with you and surpisingly every meal was very delicious and the kilos went down finally!! Highly recommend The Home office pack to those who spend everyday in the office as I do",
  "src.components.organisms.ReviewBlock.title28": "Jitka Beranova",
  "src.components.organisms.ReviewBlock.description28":
    "The first month that I have been with you is coming to an end and I already feel a thousand times better, minus 3 kilos and I finally have time and energy, you are absolutely divine",
  "src.components.organisms.ReviewBlock.title29": "Jan Kriz",
  "src.components.organisms.ReviewBlock.description29":
    "Always excellent taste and appearance, exactly tailored for me, great service all around, perfect service for me and I am finally fed in such a way that I don't have to deal with finishing my meal anywhere else. In addition, during the covid situation, NutritionPro also solved the situation for people around me who can stay and eat at home when they don't feel safe to go out and shop. So thumbs up and not only for this year, a clear choice. Thanks for her!!",
  "src.components.organisms.ReviewBlock.title30": "Eldar Alizade",
  "src.components.organisms.ReviewBlock.description30":
    "The food tastes great, I'm starving, and especially I've lost a few kilos in a month! Coz was the main target. In addition, the consultants are willing and the communication is excellent. So everything is great, I recommend!",
  "src.components.organisms.ReviewBlock.title31": "Kacko Becko",
  "src.components.organisms.ReviewBlock.description31":
    "Nice surprise, great satisfaction (I can also compare with the competition). Food according to my taste, I ordered again.",
  "src.components.organisms.ReviewBlock.title32": "Patrick Beznoska",
  "src.components.organisms.ReviewBlock.description32":
    "Great service and food too",
  "src.components.organisms.ReviewBlock.title33": "Alan Pock",
  "src.components.organisms.ReviewBlock.description33":
    "It's a blast, after a month I really like it, probably the most that I don't have to solve and always think of what to wear.",
  "src.components.organisms.ReviewBlock.title34": "Pavel Jiranek",
  "src.components.organisms.ReviewBlock.description34":
    "Tasty, quick and neat solution for your daily eating habits!",
  "src.components.organisms.ReviewBlock.title35": "Petra Dolanska",
  "src.components.organisms.ReviewBlock.description35":
    "Luxurious food, great communication and just the right portion. I just have to RECOMMEND",

  "src.components.organisms.ProgramBlock.meat.description1": "fully balanced",
  "src.components.organisms.ProgramBlock.meat.description2":
    "with a rich range of ingredients",
  "src.components.organisms.ProgramBlock.meat.description3":
    "emphasizes variety",
  "src.components.organisms.ProgramBlock.meat.description4":
    "minerals and vitamins",
  "src.components.organisms.ProgramBlock.meat.description5": "fiber.",
  "src.components.organisms.ProgramBlock.meat.description6": " 3-5 courses",
  "src.components.organisms.ProgramBlock.meat.description7":
    "options for exchanging meals",
  "src.components.organisms.ProgramBlock.meat.description8":
    " try some of our vegetarian dishes",
  "src.components.organisms.ProgramBlock.meat.description9":
    "seasonal ingredients,",
  "src.components.organisms.ProgramBlock.meat.description10": "best reviews",
  "src.components.organisms.ProgramBlock.meat.description11":
    "knowledge and research in accordance with nutritional standards",
  "src.components.organisms.ProgramBlock.meat.description12":
    "nutrition specialists.",
  "src.components.organisms.ProgramBlock.meat.description13":
    "caloric variants,",
  "src.components.organisms.ProgramBlock.meat.description14":
    "current body weight, reduce body weight",
  "src.components.organisms.ProgramBlock.meat.description15":
    " gain muscle mass.",
  "src.components.organisms.ProgramBlock.vege.description1":
    "they don't want meat and fish.",
  "src.components.organisms.ProgramBlock.vege.description2":
    "a diverse range of raw materials",
  "src.components.organisms.ProgramBlock.vege.description3":
    "enough protein, fiber, minerals and vitamins.",
  "src.components.organisms.ProgramBlock.vege.description4": "3-5 courses",
  "src.components.organisms.ProgramBlock.active.description1":
    "active sportsman",
  "src.components.organisms.ProgramBlock.active.description2":
    "greater supply of energy and nutrients.",
  "src.components.organisms.ProgramBlock.active.description3":
    "rich in protein",
  "src.components.organisms.ProgramBlock.active.description4":
    "lower amount of fat.",
  "src.components.organisms.ProgramBlock.active.description5":
    "all nutrients in an optimal ratio",
  "src.components.organisms.ProgramBlock.fish.description1":
    "without fish and fish products.",
  "src.components.organisms.ProgramBlock.fish.description2":
    "a wide selection of tasty dishes,",
  "src.components.organisms.ProgramBlock.fish.description3":
    "nutritionally valuable food.",
  "src.components.organisms.ProgramBlock.fish.description4": "3-5 courses",
  "src.components.organisms.ProgramBlock.office.description1":
    "two-course menu",
  "src.components.organisms.ProgramBlock.office.description2":
    "choose according to your own preferences.",

  "src.components.organisms.WhyNutrition.item1.title":
    "The possibility of changing any gear",
  "src.components.organisms.WhyNutrition.item2.title":
    "The average rating of our meals is 4.6/5",
  "src.components.organisms.WhyNutrition.item3.title": "Every day a new menu",
  "src.components.organisms.WhyNutrition.item4.title":
    "A menu tailored to your goals",
  "src.components.organisms.WhyNutrition.item5.title":
    "Most accurate delivery time",
  "src.components.organisms.WhyNutrition.item6.title":
    "The most flexible transport",
  "src.components.organisms.WhyNutrition.item7.title":
    "Nutritional consultation included in the price",
  "src.components.organisms.WhyNutrition.item8.title":
    "The most professional food preparation",
  "src.components.organisms.WhyNutrition.item1.description":
    "Each of us is unique and not everything is to everyone's taste. That's not a problem for us. You can exchange any course for another dish for free.",
  "src.components.organisms.WhyNutrition.item2.description":
    "We actively collect feedback from clients on each dish and based on it, we constantly improve the menu.",
  "src.components.organisms.WhyNutrition.item3.description":
    "Our menu consists of more than 300 dishes. You will not see any dish twice for 4 weeks. You can look forward to your favorite for another month.",
  "src.components.organisms.WhyNutrition.item4.description":
    "Thanks to our special algorithm, we can tailor a menu for each customer.",
  "src.components.organisms.WhyNutrition.item5.description":
    "We deliver in two-hour time slots, which you can change as you wish. We will deliver the food right under your nose at the time you set.",
  "src.components.organisms.WhyNutrition.item6.description":
    "Don't wait at home all evening for the courier. You can adjust the time and place of delivery flexibly almost up to the last minute. Simply call or write.",
  "src.components.organisms.WhyNutrition.item7.description":
    "You've been on the right track since day one. Our nutrition consultants and nutrition expert will help you with your dietary goals for free.",
  "src.components.organisms.WhyNutrition.item8.description":
    "Your meals are prepared under the supervision of a chef, a nutritionist and a production technologist, which guarantees the highest quality of prepared meals.",
  "src.components.organisms.WhyNutrition.redBtn": "Calculate the price",
  "src.components.organisms.WhyNutrition.titleFirst": "Why boxed",
  "src.components.organisms.WhyNutrition.titleSecond": "dieta NutritionPro?",
  "src.components.organisms.CityDeliveryBlock.list.description1":
    "We deliver in a green location.",
  "src.components.organisms.CityDeliveryBlock.list.description2":
    "Evening delivery between {clock}",
  "src.components.organisms.CityDeliveryBlock.list.descriptionPlzen":
    "You choose one of 3 time windows for delivery, no more all-night chasing with couriers!",
  "src.components.organisms.CityDeliveryBlock.list.description3":
    "Have your plans changed? You can flexibly change the time and place even on the day of import until 10:00",
  "src.components.organisms.CityDeliveryBlock.list.title": "How does it work?",
  "src.components.organisms.CityDeliveryBlock.title":
    "The most accurate and flexible transport",
  "src.components.organisms.CallMeBlock.call": "Call me",
  "src.components.organisms.CallMeBlock.order": "Order online",
  "src.components.organisms.CallMeModal.title": "Call me",
  "src.components.organisms.CallMeModal.programTitle":
    "Non-binding consultation",
  "src.components.organisms.CallMeModal.programDescription":
    "Do you want advice on choosing, or something is not clear to you? Leave us a contact and we will get back to you.",
  "src.components.organisms.DesktopHeader.program": "Programs",
  "src.components.organisms.DesktopHeader.customerPortal": "Customer portal",
  "src.components.organisms.DesktopHeader.menu": "Menu",
  "src.components.organisms.NotFoundBlock.title": "Page not found",
  "src.components.organisms.NotFoundBlock.btn": "Back to home page",
  "src.components.organisms.Delivery.brno.point": "1 collection point",
  "src.components.organisms.Delivery.plzen.point": "1 collection point",
  "src.components.organisms.Delivery.liberec.point": "1 collection point",
  "src.components.organisms.Delivery.hradec.point": "1 collection point",
  "src.components.organisms.Delivery.mlda.point": "1 collection point",
  "src.components.organisms.Delivery.kladno.point": "1 collection point",

  "src.components.organisms.Delivery.kladno.info":
    "SMS hourly window notification",
  "src.components.routes.pages.Terms.description":
    "of Nutritionpro s.r.o. with registered office at Inovační 122, 252 41 Zlatníky- Hodkovice, ID number 07752971",
  "src.components.routes.pages.Terms.content1.title": "I. Definition of terms",
  "src.components.routes.pages.Terms.content2.title": "II. Order",
  "src.components.routes.pages.Terms.content3.title":
    "III. Order change/cancellation",
  "src.components.routes.pages.Terms.content4.title": "IV. Delivery",
  "src.components.routes.pages.Terms.content5.title":
    "V. NutritionPro Program Defects and Claims",
  "src.components.routes.pages.Terms.content6.title":
    "VI. NutritionPro meal plan price",
  "src.components.routes.pages.Terms.content7.title": "VII. Final Arrangements",
  "src.components.routes.pages.Terms.content1.description1":
    "For the purposes of these General Terms and Conditions, the terms below have the following meanings:",
  "src.components.routes.pages.Terms.content1.description2":
    "The company Nutritionpro s.r.o. with registered office at Inovační 122, 252 41 Zlatníky-Hodkovice, ID number 07752971",
  "src.components.routes.pages.Terms.content1.description3":
    "e-mail: info@nutritionpro.cz, phone: +420 226 288 200 (hereinafter referred to as the “Operator“)",
  "src.components.routes.pages.Terms.content1.description4":
    "The NutritionPro program is a program of regular healthy eating, which includes the preparation and delivery of daily meal sets according to the number of daily/weekly/monthly cycles selected by the client and ordered by the operator, to a place designated by the client or to a collection point. (hereinafter referred to as the “NutritionPro Program“)",
  "src.components.routes.pages.Terms.content1.description5":
    "The daily meal set is a balanced combination of food for one day, delivered to the client by the Operator. The daily set of meals will be individually adapted to the optimal intake of macronutrients according to the data from the initial questionnaire filled out by the client and input data on body composition. The food in each daily set is clearly arranged, marked, packed, and thus delivered to the client once every two days to the place designated by him or to the collection point.",
  "src.components.routes.pages.Terms.content1.description6":
    "The client is a person ordering the NutritionPro Program on the web portal www.nutritionpro.cz, as a person interested in the offered services consisting of regular meals in the form of nutritionally balanced and valuable foods. The client undertakes to pay the operator the agreed remuneration for these services. (hereinafter referred to as “Client“)",
  "src.components.routes.pages.Terms.content2.description1":
    "1. The NutritionPro program can be ordered as follows:",
  "src.components.routes.pages.Terms.content2.description2":
    "2. on the phone number +420 226 288 200 when the purchase contract is concluded by confirmation of the order by the Operator during the given call,",
  "src.components.routes.pages.Terms.content2.description3":
    "3. by e-mail to the e-mail addresses listed on the website www.nutritionpro.cz, when the purchase contract is concluded by confirmation of the order by the Operator by e-mail, by telephone or by delivery of the NutritionPro Program (in the case of redelivery of the NutritionPro Program, when the Client responds with a new order to the Operator's e-mail announcing the approaching end of the previous order),",
  "src.components.routes.pages.Terms.content2.description4":
    "4. through the online form located on the website www.nutritionpro.cz, when the purchase contract is concluded by telephone confirmation of the order by the Operator.",
  "src.components.routes.pages.Terms.content2.description5":
    "5. The order for the NutritionPro Program must be placed at least 3 business days before the day the NutritionPro Program is to be delivered. Each order placed is a binding proposal to conclude a purchase contract.",
  "src.components.routes.pages.Terms.content2.description6":
    "6. If the client suffers from an allergy to any of the foods, he is obliged to inform the operator immediately by telephone, while the procedure for solving such a situation will be agreed upon at the same time.",
  "src.components.routes.pages.Terms.content3.description1":
    "1. The Client is obliged to notify the Operator of changes to the order, such as cancellation of the NutritionPro Program order or change of the ordered program, at least 3 working days before the day on which the NutritionPro Program is to be delivered. Changes that the Client informs the Operator of in a shorter period of time than stated in the previous sentence will not be complied with by the Operator, the NutritionPro Program will be properly delivered to the Client and the Client is obliged to pay the Operator the price for the NutritionPro Program delivered in this way in full.",
  "src.components.routes.pages.Terms.content3.description2":
    "If the Client changes/cancels the order during customer support working hours, i.e. Mon-Fri from 10:00 a.m. to 12:00 p.m. and from 1:00 p.m. to 6:00 p.m. by calling +420 226 288 200, but no later than 3 full working days before on the day of delivery (however, the day of delivery itself is not counted, i.e. the day of delivery minus 3 full working days), the Operator will change/cancel the order thus changed/cancelled.",
  "src.components.routes.pages.Terms.content4.description1":
    "The Operator undertakes to produce the NutritionPro Program according to the Client's order and deliver it to the place specified by the Client and hand it over to the Client there in the manner specified in paragraph 2 of this article.",
  "src.components.routes.pages.Terms.content4.description2":
    "The driver delivering the NutritionPro Program will hand over the daily set of meals to the Client, a person designated by the Client or another person present at the location designated by the Client as the place of delivery of the NutritionPro Program. The moment the NutritionPro Program is handed over to the Client, the risk of damage to the Program passes to the Client. If there is no person at the place designated by the Client as the place of delivery to whom the NutritionPro Program could be handed over, and the Client is not available by phone, the NutritionPro Program will be stored and the Client will be informed about the collection options.",
  "src.components.routes.pages.Terms.content4.description3":
    "The daily set of meals is delivered in a chilled state and must be kept cold until it is consumed, i.e. at a maximum temperature of 5 °C. The Operator bears no responsibility for the quality of the NutritionPro Program if the storage conditions are violated after it has been handed over to the Client.",
  "src.components.routes.pages.Terms.content4.description4":
    "The daily set of meals must be consumed no later than the day indicated on the label. It must be consumed immediately after opening.",
  "src.components.routes.pages.Terms.content4.description5":
    "The daily set of meals will be delivered to the designated location between 5 and 10 p.m. The client is aware that in exceptional cases the delivery time may change, taking into account the traffic situation and other similar events. The client will be informed about this change by phone.",
  "src.components.routes.pages.Terms.content4.description6":
    "If the Client needs to change the agreed delivery time to the place specified by the client, the client is entitled to make this change via email or by calling +420 226 288 200 up to 12 noon on the day of delivery of the daily meal set.",
  "src.components.routes.pages.Terms.content5.description1":
    "1. The operator is not responsible for defects caused by: unauthorized intervention, inappropriate storage, unprofessional handling, as a result of using the goods for purposes other than those intended, as a result of a natural disaster or force majeure after the goods have been handed over to the Client.",
  "src.components.routes.pages.Terms.content5.description2":
    "When taking over the goods, the buyer is obliged to check whether the delivered goods have any defects caused by transport. If so, the buyer is obliged not to take over the goods and to inform the seller immediately via email at the address: info@nutritionpro.cz via the form {button} The buyer is also obliged to check the delivered goods without unnecessary delay, especially the delivered quantity and type of delivered goods. In case of detected deficiencies, it is necessary to inform about it immediately. Later complaints will not be taken into account.",
  "src.components.routes.pages.Terms.content5.description3":
    "3. In the event of a food quality complaint, the Seller's driver will pick up the claimed goods. The condition for a food quality complaint is that no more than 10% of the food portion must be missing. If the seller considers the complaint to be justified, it will be resolved either by exchanging it for another meal at the same price level, or by returning the purchase price in the next order.",
  "src.components.routes.pages.Terms.content5.description4":
    "4. The client is obliged to substantiate the legitimacy of his claim, which he applies via the form according to paragraph 2 above, also with the attached photo, which proves the objected defect.",
  "src.components.routes.pages.Terms.content6.description1":
    "1. The Client undertakes to take over the ordered NutritionPro Program from the Operator at the location designated by him, and to pay the Operator the Purchase Price for this Program properly and on time.",
  "src.components.routes.pages.Terms.content6.description2":
    "2. The Operator invoices the Purchase Price to the Client with an invoice with a due date within 7 days from the date of issue of the invoice. The Operator is entitled to issue an invoice to the Client on the day the Client orders the NutritionPro Program and sends the invoice to the Client by e-mail.",
  "src.components.routes.pages.Terms.content6.description3":
    "3. The Client pays the Operator the Purchase Price on the basis of the issued invoice by non-cash transfer to the Operator's bank account under the variable symbol specified in the invoice.",
  "src.components.routes.pages.Terms.content6.description4":
    "4. The Client pays the Operator the Purchase Price on the basis of the issued invoice by non-cash transfer to the Operator's bank account under the variable symbol specified in the invoice.",
  "src.components.routes.pages.Terms.content6.description5":
    "5. The purchase price is considered paid at the time it is credited to the Operator's bank account.",
  "src.components.routes.pages.Terms.content6.description6":
    "6. In case of delay in payment of the Purchase Price, the Operator may require the Client to pay interest on the delay.",
  "src.components.routes.pages.Terms.content6.description7":
    "7. In case of delay in payment of the Purchase Price, the Operator may refuse delivery of the ordered Program until full payment of the Purchase Price for previous deliveries of the NutritionPro Program.",
  "src.components.routes.pages.Terms.content7.description1":
    "1. All conditions not expressly regulated by the contract are governed by the generally binding legal regulations of the Czech Republic, in particular the provisions of Act No. 89/2012 Coll., Civil Code, as amended.",
  "src.components.routes.pages.Terms.content7.description2":
    "2. These binding terms and conditions are valid and effective from the date of their publication on the operator's website. The operator will inform clients in advance of any changes or additions to these binding contractual terms. In the event that any of the provisions of these terms and conditions subsequently turn out to be invalid or ineffective, this fact does not mean the invalidity of the terms and conditions as a whole. The operator undertakes to replace such an invalid or ineffective agreement with another agreement and to inform the client about this change. If the client does not disagree with the change within 30 days from the date of its notification, it is considered that he agrees with the change in the contractual terms.",
  "src.components.routes.pages.Terms.content7.description3":
    "3. By accepting these contractual terms and conditions by the client, they are considered binding between him and the operator, and the client has no reservations regarding the content of the Agreement and contractual terms.",
  "src.components.routes.pages.Terms.btn": "LINK HERE",
  "src.components.organisms.forms.TermsForm.btn": "Send",
  "src.components.organisms.TermsModal.title": "Complaint form",
  "src.components.organisms.forms.TermsForm.checkbox":
    "Consent to the processing of personal data for marketing purposes.",

  "src.components.routes.pages.Gdpr.description1":
    "Společnost Nutritionpro s.r.o. se sídlem Inovační 122, 252 41 Zlatníky- Hodkovice, IČO 07752971, e-mail: info@nutritionpro.cz, tel: +420 777 510 839 (dále jen „Provozovatel“) ve smyslu nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále jen: „GDPR”) dbá zásad ochrany osobních údajů a zpracovává pouze ty osobní údaje, které slouží k poskytování služby vybrané zákazníkem a po dobu k tomuto účelu nezbytnou.",
  "src.components.routes.pages.Gdpr.description2":
    "Provozovatel zpracovává dále osobní údaje zástupců zákazníků (smluvních stran) a také potenciálních zákazníků, kteří mají zájem o služby Provozovatele. Účely zpracování osobních údajů jakož i informace o právních titulech, oprávněných zájmů a informace ohledně toho, že jsou osobní informace zpracovány pouze na základě svobodného a aktivního souhlasu klienta jsou vždy obsaženy v uzavřené smlouvě, nebo v obchodních podmínkách uveřejněných na webu Provozovatele. Ke zpracování osobních údajů dochází také za účelem plnění zákonných povinností Provozovatele vyvstávajících z daňového řádu, nebo jiného zákonného právního předpisu, i z jiných účelů, které sledují oprávněný zájem Provozovatele, tedy:",
  "src.components.routes.pages.Gdpr.description3":
    "1. evidence klientů, kteří byli kontaktováni obchodními zástupci Provozovatele v souvislosti s nabídkou na uzavření smlouvy, neb",
  "src.components.routes.pages.Gdpr.description4":
    "2. z důvodu předcházení podvodům, účely {info}",
  "src.components.routes.pages.Gdpr.description4.info":
    "kontroly nastavených technologických procesů",
  "src.components.routes.pages.Gdpr.description5":
    "3. náboru nových zaměstnanců, kteří v rámci přijímací pohovoru sdělí Provozovatele své osobní údaje. Údaje neúspěšných uchazečů jsou uchovány maximálně po dobu 6 měsíců za účelem jejich případného užití spočívající v oslovení s jinou nabídkou zaměstnání/ účasti v jiném nebo opakovaném výběrovém řízení na obsazení pracovní pozice, nedohodnou-li se strany jinak,",
  "src.components.routes.pages.Gdpr.description6":
    "4. přímého marketingu, kterým se rozumí zpracování osobních údajů stávajících zákazníků za účelem přímého oslovení obchodní nabídkou nebo výzkumem. Provozovatel upozorňuje, že pokud zákazník podá u konkrétní služby námitku proti přímému marketingu, Provozovatel již nadále nebude nijak zpracovávat osobní údaje zákazníka za tímto účelem, stejně tak jako přestane zákazníka oslovovat/zasílat obchodní nabídky,",
  "src.components.routes.pages.Gdpr.description7":
    "5. výkon a obhajoba nároků Provozovatele, případně",
  "src.components.routes.pages.Gdpr.description8":
    "z důvodu výkonu a prosazování právních nároků v rámci reklamací,soudních sporů a výkonů rozhodnutí.",
  "src.components.routes.pages.Gdpr.description9":
    "Informace obsažené v tomto dokumentu doplňují informace, které jsou zákazníkům poskytnuty v uzavírané smlouvě.",
  "src.components.routes.pages.Gdpr.description10":
    "Totožnost a kontaktní údaje správce",
  "src.components.routes.pages.Gdpr.description11":
    "Společnost Nutritionpro s.r.o. se sídlem Inovační 122, 252 41 Zlatníky- Hodkovice, IČO 07752971, e-mail: info@nutritionpro.cz, tel: +420 777 510 839",
  "src.components.routes.pages.Gdpr.description12":
    "Kontaktní údaje Pověřence pro ochranu osobních údajů",
  "src.components.routes.pages.Gdpr.description13":
    "Ve všech záležitostech souvisejících se zpracováním vašich osobních údajů je vám k dispozici náš pověřenec pro ochranu osobních údajů.",
  "src.components.routes.pages.Gdpr.description14":
    "Pověřence lze kontaktovat emailem na adrese info@nutritionpro.cz",
  "src.components.routes.pages.Gdpr.description15":
    "Poučení o právu podat námitky",
  "src.components.routes.pages.Gdpr.description16":
    "Ve všech případech zpracovávání osobních informací ze strany Provozovatele má klient právo podat proti takovému zpracování námitku. Námitka je posléze posouzena, a pokud pro to existují náležité zákonné důvody, bude ji vyhověno. ",
  "src.components.routes.pages.Gdpr.description17": "Zabezpečení",
  "src.components.routes.pages.Gdpr.description18":
    "Všechny zpracovávané osobní údaje jsou zabezpečeny náležitými organizačně technických opatřeními. ",
  "src.components.routes.pages.Gdpr.description19": "Příjemci osobních údajů",
  "src.components.routes.pages.Gdpr.description20":
    "Příjemci osobních údajů je fyzická nebo právnická osoba, orgán veřejné moci, agentura nebo jiný subjekt, kterým jsou osobní údaje poskytnuty, ať už se jedná o třetí stranu, či nikoli. Orgány veřejné moci, které mohou získávat osobní údaje v rámci zvláštního šetření v souladu s právem členského státu, se za příjemce nepovažují; zpracování těchto osobních údajů těmito orgány veřejné moci musí být v souladu s použitelnými pravidly ochrany údajů pro dané účely zpracování. ",
  "src.components.routes.pages.Gdpr.description21":
    "Provozovatel předává osobní údaje pouze následujícím příjemcům, kteří se podílejí se na dodání zboží / služeb / realizaci plateb na základě smlouvy, zajišťující marketingové služby.",
  "src.components.routes.pages.Gdpr.description22":
    "Správce nemá v úmyslu předat osobní údaje do třetí země (do země mimo EU) nebo mezinárodní organizaci.",
  "src.components.routes.pages.Gdpr.description23":
    "Základní doba, po kterou jsou osobní údaje uloženy, je vždy stanovená v uzavírané smlouvě mezi klientem a Provozovatelem. ",
  "src.components.routes.pages.Gdpr.description24":
    "Základní doba, po kterou jsou osobní údaje uloženy, je vždy stanovená v uzavírané smlouvě mezi klientem a Provozovatelem.",
  "src.components.routes.pages.Gdpr.description25":
    "Osobní údaje potenciálních klientů, kteří předali své osobní údaje, ale smlouvu neuzavřeli, jsou osobní údaje uchovávány po dobu 1 roku, a to pro evidenční účely. ",
  "src.components.routes.pages.Gdpr.description26":
    "Pro evidenční účely na dobu 10 let jsou uchovávány osobní údaje klientů od okamžiku ukončení účinnosti smlouvy.",
  "src.components.routes.pages.Gdpr.description27": "Ukládané údaje",
  "src.components.routes.pages.Gdpr.description28.info": "Kontaktní údaje:",
  "src.components.routes.pages.Gdpr.description28":
    "{info} Jméno, adresa, telefonní číslo, e-mailová adresa, ičo a to z toho důvodu, že zpracování je nezbytné pro splnění smlouvy, jejíž smluvní stranou je subjekt údajů, nebo pro provedení opatření přijatých před uzavřením smlouvy na žádost tohoto subjektu údajů, případně je zpracování nezbytné pro účely oprávněných zájmů příslušného správce či třetí strany, kromě případů, kdy před těmito zájmy mají přednost zájmy nebo základní práva a svobody subjektu údajů vyžadující ochranu osobních údajů. Účelem tak je, aby Provozovatel věděl, co po něm Klient požaduje a z jakého důvodu ho kontaktoval.",
  "src.components.routes.pages.Gdpr.description29.info1": "Lokalizační údaje:",
  "src.components.routes.pages.Gdpr.description29.info2":
    "Co do důvodů a účelu platí obdobně to, co je sděleno výše u kontaktních údajů, zejm. však pro účely doručování.",
  "src.components.routes.pages.Gdpr.description29":
    "{info1} Adresa, PSČ, město, stát. {info2}",
  "src.components.routes.pages.Gdpr.description30.info1": "Komunikační údaje:",
  "src.components.routes.pages.Gdpr.description30.info2":
    "Co do důvodů a účelu platí obdobně to, co je sděleno výše u kontaktních údajů, zejm. však z důvodu mohl, aby mohl Provozovatel Klienta kontaktovat za účelem plnění dle smlouvy, s náležitým a uctivým oslovením, případně z důvodu řešení komplikací spojených s doručováním, nebo řešením reklamací.",
  "src.components.routes.pages.Gdpr.description30":
    "{info1} Jméno, e-mailová adresa, telefonní číslo. {info2}",
  "src.components.routes.pages.Gdpr.description31.info1": "Platební údaje:",
  "src.components.routes.pages.Gdpr.description31.info2":
    "Co do důvodů a účelu platí obdobně to, co je sděleno výše u kontaktních údajů, zejm. však, aby mohl Provozovatel sledovat platby a přiřadit je k řešeným objednávkám, případně k řešení reklamací.",
  "src.components.routes.pages.Gdpr.description31":
    "{info1} způsob platby. {info2}",
  "src.components.routes.pages.Gdpr.description32.info1": "Dodací údaje:",
  "src.components.routes.pages.Gdpr.description32.info2":
    "Co do důvodů a účelu platí obdobně to, co je sděleno výše u kontaktních údajů, zejm. však dodání objednávek. Našim řidičům jsou předávány pouze nezbytné údaje pro doručení.",
  "src.components.routes.pages.Gdpr.description32":
    "{info1} Jméno, dodací adresa, telefonní číslo, identifikační číslo zákazníka. {info2}",
  "src.components.routes.pages.Gdpr.description33": "Další ukládané údaje",
  "src.components.routes.pages.Gdpr.description34":
    "Uchovávány jsou také veškeré další informace, osobní údaje, které klienti předají o jiných fyzických osobách, se kterými spolupracuje nebo jejichž údaje jsou pro plnění smluvního vztahu nezbytné. Tyto osoby je klient povinen obdobným způsobem informovat o předání jejich osobních údajů.",
  "src.components.routes.pages.Gdpr.description35":
    "Stejně tak Provozovatel shromažďuje osobní údaje, které získá v průběhu telefonních hovorů, případně také na sociálních sítích (facebook, twitter, apod.). Telefonní hovor může být monitorován a nahráván za účelem zajištění kvality a řádného fungování našich služeb. Záznamy mohou být uchovány až šest měsíců pouze pro interní potřeby. Co do důvodů a účelu platí obdobně to, co je sděleno výše u kontaktních údajů.",
  "src.components.routes.pages.Gdpr.description36":
    "Konkrétní účely zpracovávání údajů",
  "src.components.routes.pages.Gdpr.description37.info": "Řidiči/doručovatelé:",
  "src.components.routes.pages.Gdpr.description37":
    "{info} Těmto osobám, ať již jsou v zaměstnaneckém, nebo obdobném poměru, nebo se jedná o třetí stranu, která je pověřena doručením objednávky jsou předávány údaje nezbytné pro doručení objednávky. Kategorií osobních údajů jsou dodací údaje.Ve smyslu § 6 odst., písm. b), GDPR.",
  "src.components.routes.pages.Gdpr.description38.info1": "Přímý marketing:",
  "src.components.routes.pages.Gdpr.description38.info2":
    "Kategorií osobních údajů jsou",
  "src.components.routes.pages.Gdpr.description38":
    "{info1}  Sdělením vaší emailové adresy souhlasíte sezasíláním našich obchodních nabídek a to prostřednictvím emailu. Zpracování osobních údajů (telefonních čísel, e-mailových adres apod.) zákazníků pro účely přímého marketingu (při rozesílce obchodních sdělení) je zpracování prováděné z důvodu oprávněného zájmu dle čl. 6 odst. 1 písm. f) GDPR. Informujeme tímto naše klienty o jejich právu vznést námitku dle čl. 21 odst. 2 GDPR proti zpracování osobních údajů pro účely přímého marketingu. Pokud Klient tuto námitku vznese, nesmějí již být osobní údaje pro tento účel zpracovány (jde tedy o tzv. princip opt-out), viz níže, odvolání svého souhlasu. {info2} kontaktní údaje a lokalizační údaje. V případě, že klient nesouhlasí od počátku se zasíláním obchodních sdělení, emailů, apod. výše, v rámci přímého marketingu, ať Provozovateli sdělí tento svůj požadavek v rámci své první objednávky, případně ať předem zašle toto sdělení na níže uvedenou emailovou adresu, viz. odvolání svého souhlasu.",
  "src.components.routes.pages.Gdpr.description39.info": "Cílení (targeting).",
  "src.components.routes.pages.Gdpr.description39":
    "{info}  Cílením se má přizpůsobování, tj. vypínání, aktivace a modulace reklamních bannerů na webových stránkách určitým cílovým skupinám za účelem co nejefektivnějším sdělení uživateli webu/ potenciálnímu zákazníkovi na individualizované bázi.",
  "src.components.routes.pages.Gdpr.description40":
    "Retargetting. Retargettingem se rozumí, pokud využití souborů cookie v případě opětovného navštívení webových stránek Provozovatele. Tato funkce může být deaktivována také deaktivací doplňků ve svém webovém prohlížeči, případně omezena až deaktivována mazáním souborů cookie. Kategorií osobních údajů jsou kontaktní údaje.",
  "src.components.routes.pages.Gdpr.description41":
    "Cookies. Cookie je krátký textový soubor, který si navštívený web ukládá ve vašem prohlížeči. Umožňuje webu zaznamenat informace o vaší návštěvě.Příští návštěva tak pro vás může být snazší a web bude užitečnější. Většina prohlížečů umožňuje ovládat způsob nastavení a používání souborů cookie při prohlížení a umožňuje smazat soubory cookie i údaje o prohlížení. Váš prohlížeč navíc možná nabízí správu souborů cookie na jednotlivých webech. K užívání cookies z naší strany můžete vyjádřit svůj nesouhlas a to patřičným sdělením na níže uvedený email, viz. Odvolání svého souhlasu. Vaši žádost vyřešíme bez zbytečného odkladu , ovšem Provozovatel upozorňuje na to, že tento nesouhlas bude mít svůj účinek pouze v rámci webového prohlížeče, ze kterého svůj nesouhlas vytvoříte.",
  "src.components.routes.pages.Gdpr.description42.info":
    "Odvolání svého souhlasu",
  "src.components.routes.pages.Gdpr.description42":
    "{info} docílíte sdělením, že odvoláváte svůj souhlas se užitím osobních údajů na adresu info@nutritionpro.cz. Na tuto adresu posílejte také své případné NÁMITKY proti zpracování osobních údajů.",
  "src.components.routes.pages.Gdpr.description43": "Vaše práva",
  "src.components.routes.pages.Gdpr.description44":
    "1. Za podmínek stanovených v GDPR máteDále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu osobních údajů.",
  "src.components.routes.pages.Gdpr.description45":
    "2. Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu osobních údajů.",
  "src.components.routes.pages.Gdpr.description46": "Závěrečná ustanovení",
  "src.components.routes.pages.Gdpr.description47":
    "1.Odesláním objednávky z internetového objednávkového formuláře potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.",
  "src.components.routes.pages.Gdpr.description48":
    "2. S těmito podmínkami souhlasíte zaškrtnutím souhlasu prostřednictvím internetového formuláře. Zaškrtnutím souhlasu potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.",
  "src.components.routes.pages.Gdpr.description49":
    "Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních údajů zveřejní na svých internetových stránkách, případně Vám zašle novou verzi těchto podmínek na e-mailovou adresu, kterou jste správci poskytl/a.",
  "src.components.routes.pages.Gdpr.description50":
    "Vyhrazujeme si právo toto prohlášení o ochraně osobních údajů změnit v souladu s právními ředpisy. O případných významných změnách, jako jsou změny účelu nebo nové účely zpracování, vás budeme informovat.",
  "src.components.organisms.forms.TermsForm.name": "Jméno a příjmení",
  "src.components.organisms.forms.TermsForm.email": "E-mail",
  "src.components.organisms.forms.TermsForm.fakutra": "Číslo faktury",
  "src.components.organisms.forms.TermsForm.comment": "Příčina reklamace",
  "src.components.organisms.forms.TermsForm.comment2":
    "Váš požadavek na řešení",
  "src.components.routes.pages.VyzkousejMenu.title": "VyzkousejMenu",
  "src.components.molecules.DeliveryCard.btn": "Ukázat více",
  "src.components.atoms.KalorieItem.kalorie": "kcal",
  "src.components.organisms.OrderOnlineFirstStep.people":
    "Objednávám jídlo pro",
  "src.components.organisms.OrderOnlineFirstStep.menu": "Vyberte si menu",
  "src.components.organisms.OrderOnlineFirstStep.kalorie":
    "Zvolte Váš kalorický příjem {kkal}",
  "src.components.organisms.OrderOnlineFirstStep.kalorie.symbol": "(kcal)",
  "src.components.organisms.OrderOnlineFirstStep.program": "Jaký máte cíl?",
  "src.components.organisms.OrderOnlineFirstStep.duration": "Délka programu",
  "src.components.organisms.OrderOnlineFirstStep.duration.description":
    "Jak dlouho chcete jídlo dovážet?",
  "src.components.organisms.OrderOnlineFirstStep.dayWeek": "Délka týdne",
  "src.components.organisms.OrderOnlineFirstStep.dayWeek.description":
    "Kolik dní v týdnu budeme dovážet?",
  "src.components.molecules.OnlineMenuSlider.meat.title":
    "NutritionPro Classic",
  "src.components.molecules.OnlineMenuSlider.light.title": "NutritionPro Light",
  "src.components.molecules.OnlineMenuSlider.vege.title": "NutritionPro Vege",
  "src.components.molecules.OnlineMenuSlider.fish.title": "Menu bez ryb",
  "src.components.molecules.OnlineMenuSlider.combo.title": "NutritionPro kombo",
  "src.components.molecules.OnlineProgramBlock.lose.title": "Chci zhubnout",
  "src.components.molecules.OnlineProgramBlock.gain.title": "Chci nabrat svaly",
  "src.components.molecules.OnlineProgramBlock.maintanace.title":
    "Chci si udržet svou váhu",
  "src.components.molecules.OnlineProgramBlock.lunch.title":
    "Nechci doma vařit",
  "src.components.molecules.ProgramWeekDuration.dni": " {day} dní",
  "src.components.molecules.ProgramWeekDuration.info6": "(pondělí až sobota)",
  "src.components.molecules.ProgramWeekDuration.info5": "(pondělí až pátek)",
  "src.components.molecules.ProgramWeekDuration.info4": "( pondělí až čtvrtek)",

  "src.components.molecules.OnlinePersonCard.sebe": "Sebe",
  "src.components.molecules.OnlinePersonCard.people": "2 osoby",
  "src.components.molecules.OnlinePersonCard.lidi": "5+ lidí",
  "src.components.molecules.OnlinePersonCard.tooltip":
    "Pro objednávku pro více osob použijte formulář 'Mám zájem'",
  "src.components.molecules.OnlinePersonCard.info":
    "*Sleva platí pouze na první objednávku",
  "src.components.molecules.OrderRightCard.call": "Mám zájem",
  "src.components.molecules.OrderRightCard.nebo": "nebo",
  "src.components.templates.OnlineOrderMobileTemplate.close": "Zrušit",
  "src.components.organisms.PromoCodeModal.title": "Promokód",
  "src.components.organisms.PromoCodeModal.field": "Vložit promokód",
  "src.components.organisms.forms.PromoCodeForm.btn": "Uplatnit",
  "src.components.organisms.forms.PromoCodeForm.errorMessage":
    "Tento promo kód není platný",
  "src.components.molecules.OrderRightCard.call.title": "Mám zájem",
  "src.components.organisms.OnlineOrderSecondStep.info": "Osobní informace",
  "src.components.organisms.OnlineOrderSecondStep.name": "Jméno a příjmení",
  "src.components.organisms.OnlineOrderSecondStep.email": "E-mail",
  "src.components.organisms.OnlineOrderSecondStep.psc": "PSČ",
  "src.components.organisms.OnlineOrderSecondStep.ico": "IČO ",
  "src.components.organisms.OnlineOrderSecondStep.dic": "DIČ",
  "src.components.organisms.OnlineOrderSecondStep.factura": "Fakturační adresa",
  "src.components.organisms.OnlineOrderSecondStep.companyName":
    "Název společnosti",
  "src.components.organisms.OnlineOrderSecondStep.switchCompany":
    "Objednat na firmu",
  "src.components.organisms.OnlineOrderSecondStep.companyInfo":
    "Fakturační údaje",
  "src.components.molecules.OrderSecondStepRightCard.title": "Cena objednávky",
  "src.components.molecules.OnlineOrderAddressCheck.city": "Město",
  "src.components.molecules.OnlineOrderAddressCheck.pickUpPoint":
    "Odberné místo",
  "src.components.molecules.OnlineOrderAddressCheck.address": "Adresa",
  "src.components.organisms.OnlineOrderThiredStep.calendar.title":
    "Začátek programu",
  "src.components.organisms.OnlineOrderThiredStep.calendar.description":
    "Zvolte datum, kdy chcete začít odebírat naše krabičky. První krabičky vám doručíme den před vybraným datem.",
  "src.components.organisms.OnlineOrderThiredStep.time.title":
    "Časové okno rozvozu",

  "src.components.organisms.OnlineOrderThiredStep.time.prague":
    "Vyber si nejvhodnější 2 hodinové okno rozvozu. Naši kurýři rozváží krabičky s jídlem od 18:00 do 22:00.",
  "src.components.organisms.OnlineOrderThiredStep.time.prague-area":
    "Vyber si nejvhodnější 2 hodinové okno rozvozu. Naši kurýři rozváží krabičky s jídlem od 18:00 do 22:00.",
  "src.components.organisms.OnlineOrderThiredStep.time.kladno":
    "Vyber si nejvhodnější 2 hodinové okno rozvozu. Naši kurýři rozváží krabičky s jídlem od 18:00 do 22:00.",
  "src.components.organisms.OnlineOrderThiredStep.time.plzen":
    "Vyber si nejvhodnější 2 hodinové okno rozvozu. Naši kurýři rozváží krabičky s jídlem od 17:00 do 21:00.",
  "src.components.organisms.OnlineOrderThiredStep.platba": "Zvolte platbu",
  "src.components.organisms.OnlineOrderThiredStep.platba.gopay":
    "Zabezpečenou platbu poskytuje",
  "src.components.molecules.PromoCodeApprove.promoCode": "Promokód ",
  "src.components.molecules.PromoCodeApprove.remove": "Odebrat",
  "src.components.molecules.OnlineOrderThiredStepSidebar.discount":
    "Množstevní sleva",
  "src.components.molecules.OnlineOrderThiredStepSidebar.confirm": "Objednat",
  "src.components.organisms.TimeSlotModal.title": "Časové okno rozvozu",
  "src.components.organisms.TimeSlotModal.field":
    "Vyberte si nejvhodnější 2 hodinové okno.",
  "src.components.organisms.TimeSlot.label": "Vyberte čas doručení",
  "src.components.organisms.CalendarOrder.label": "Vyberte datum",
  "src.components.organisms.CalendarModal.title": "Začátek programu",
  "src.components.organisms.CalendarModal.btn": "Potvrdit datum",
  "src.components.organisms.OrderOnlineFirstStep.porcel.description":
    "Kolik jídel ti budeme vozit?",
  "src.components.organisms.OrderOnlineFirstStep.porcel": "Počet jídel:",
  "src.components.organisms.HowCook.tab1.title": "Nejflexibilnější jídelníček",
  "src.components.organisms.HowCook.tab2.title": "Nejvyšší kvalita jídla",
  "src.components.organisms.HowCook.tab3.title": "Nejpřesnější doprava",
  "src.components.organisms.HowCook.tab1.title1":
    "Vybírej až ze 4 jídel na každý chod",
  "src.components.molecules.PorcelBlock.menu": "chodové menu",
  "src.components.organisms.HowCook.tab1.descritpion1":
    "Máš chuť na sladkou, nebo slanou snídani? Dáš přednost lehkému salátu před teplou večeří? U nás je to na tobě!",
  "src.components.organisms.HowCook.tab1.title2":
    "Navol si jídelníček v klientské zóně",
  "src.components.organisms.HowCook.tab1.description2":
    "Vše si navolíš jednoduše v našem intuitivním uživatelském prostředí, kde zároveň vidíš i jak změna ovlivní rozložení makronutrientů.",
  "src.components.organisms.HowCook.tab1.title3": "Uzpůsob si příjem dle sebe",
  "src.components.organisms.RateBlock.subTitle":
    "10 jídel na 2 dny se slevou {greenTitle}",
  "src.components.organisms.BackBoxesRateBlock.subTitle1":
    "{boldTitle} na tvoji další objednávku!",
  "src.components.organisms.BackBoxesRateBlock.subTitleBold1": "Sleva 1500 Kč",
  "src.components.organisms.BackBoxesRateBlock.subTitle2":
    "Promokód:{boldTitle}",
  "src.components.organisms.BackBoxesRateBlock.subTitleBold2": "ZPATKY1500",
  "src.components.organisms.HowCook.tab1.description3":
    "Kalorie si můžeš libovolně volit od 800-2600 kcal s přesností na 100 kcal a počet chodů od 2 do 5 s možností dokoupit extra porci.",
  "src.components.organisms.HowCook.tab1.title4":
    "Doplň si jídelníček o extra vitamíny",
  "src.components.organisms.HowCook.tab1.descripton4":
    "K jídlu si můžeš také dokoupit vitamínové balíčky v pohodlném jednodenním balení.",
  "src.components.molecules.OnlineOrderAddressCheck.tab1": "Doručení na adresu",
  "src.components.molecules.OnlineOrderAddressCheck.tab2":
    "Doručení na odběrné místo",
  "src.components.organisms.HowCook.tab2.title1": "Neboj se o kvalitu přípravy",
  "src.components.organisms.HowCook.tab2.descritpion1":
    "Jídlo připravujeme v nejmodernějších kuchyních pod kontrolou našeho technologa a kvalitáře, dle nejvyšších hygienických standardů.",
  "src.components.organisms.HowCook.tab2.title2": "Užij si maximální čerstvost",
  "src.components.organisms.HowCook.tab2.description2":
    "Po celou dobu od výroby po doručení měříme zachování teplotního řetězce pro maximální kvalitu a čerstvost jídla při dodání.",
  "src.components.organisms.HowCook.tab2.title3": "Jez jen ty nejlepší kalorie",
  "src.components.organisms.HowCook.tab2.description3":
    "Složení jídel je kontrolováno naší nutriční specialistkou a dbáme na to, aby kalorie v jídle pocházeli z těch nejkvalitnějších zdrojů, bez použití umělých sladidel.",
  "src.components.organisms.HowCook.tab2.title4": "Vyzkoušej rozmanitost jídel",
  "src.components.organisms.HowCook.tab2.descripton4":
    "V jídelníčku máme z každé kuchyně něco, od lasagních po butter chicken, ale neboj, pokud ti exotika nevyhovuje, vždy si můžeš jídlo nahradit, třeba zdravým gulášem.",
  "src.components.organisms.HowCook.tab3.title1":
    "Vyber si kdy ti budeme vozit",
  "src.components.organisms.HowCook.tab3.descritpion1":
    "V Praze vozíme v 2 hodinových oknech, které si sám navolíš, tedy nemusíš každý den čekat, jak to zrovna vyjde kurýrovi.",
  "src.components.organisms.HowCook.tab3.title2":
    "Flexibilně si změň místo a čas",
  "src.components.organisms.HowCook.tab3.description2":
    "Místo i čas dovozu si ještě ten samý den můžeš změnit, případně si nechat dovézt na odběrné místo.",
  "src.components.organisms.HowCook.tab3.title3":
    "Využij jedno z našich odběrných míst",
  "src.components.organisms.HowCook.tab3.description3":
    "Odebírat můžeš na jednom z našich 10 odběrných míst, pohodlně umístěných na frekventovaných místech jako jsou fitness centra.",
  "src.components.organisms.HowCook.tab3.title4":
    "Nech si přivézt do více než 10 měst v ČR",
  "src.components.organisms.HowCook.tab3.descripton4":
    "Zároveň si můžeš mezi městy i měnit místo dovozu, tedy pokud tě čeká pracovní cesta, či návštěva příbuzných, krabičky ti doručíme kam bude potřeba.",
  "src.components.moelecules.BenefitLabels.text1":
    "Výběr ze {head} u každého chodu podle chuti.",
  "src.components.moelecules.BenefitLabels.subText1": "4 jídel ",
  "src.components.moelecules.BenefitLabels.text2":
    "Rozvážíme jídlo ve {head}, nebo na odběrné místa.",
  "src.components.moelecules.BenefitLabels.subText2": "2 hodinových oknech",
  "src.components.moelecules.BenefitLabels.text3":
    "Kalorie přes {head}, ne omáčky.",
  "src.components.moelecules.BenefitLabels.subText3": "bílkoviny",
  "src.components.moelecules.BenefitLabels.text4":
    "Pauza {head}, stačí zavolat.",
  "src.components.moelecules.BenefitLabels.subText4": "kdykoliv zdarma",
  "src.components.molecules.InfoHeader.text":
    "{info} U nás si nově můžeš vybírat u každého chodu až ze 4 variant jídel pohodlně v klientské zóně",
  "src.components.molecules.InfoHeader.info": "Novinka!",
  "src.components.atoms.HowCookItem.info": "Více info",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms1":
    "Pokračováním souhlasíte s",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms1.link":
    "obchodními podmínkami.",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms2":
    "Jak pracujeme s vašimi osobními údaji, najdete ",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms2.link": "tady.",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDay":
    "Cena za menu (bez dopravy):",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceTotal":
    "Cena za celé období:",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDelivery":
    "Cena dopravy za den:",
  "src.components.routes.pages.PaymentNotCompleted.title":
    "Payment not complite",
  "src.components.routes.pages.ThankYouOrder.title": "Order complited",
  "src.components.organisms.PaymentModal.succsesTitle":
    "Děkujeme, vaši objednávku jsme úspěšně přijali.",
  "src.components.organisms.PaymentModal.succsesText":
    "Mezitím jsme vám do e-mailu poslali veškeré informace k dokončení platby bankovním převodem.",
  "src.components.organisms.Faq.menu.content1.bold": "krabičková dieta",
  "src.components.organisms.Faq.menu.content2.bold": "krabičkovou dietu",
  "src.components.organisms.Faq.menu.content3.bold": "krabičková dieta",
  "src.components.organisms.Faq.menu.content3.boldInfo": "ukázku jídelníčku",
  "src.components.organisms.Faq.materials.content1.bold": "krabičkovou dietu",
  "src.components.organisms.Faq.materials.content2.bold": "krabičkové diety",
  "src.components.organisms.Faq.materials.content3.bold": "krabičkovou dietu",
  "src.components.organisms.Faq.materials.content4.bold": "krabičková dieta",
  "src.components.organisms.Faq.delivery.content1.bold": "krabičkové diety",
  "src.components.organisms.Faq.delivery.content2.bold": "krabičkové diety",
  "src.components.organisms.Faq.delivery.content3.bold": "krabičkové diety",
  "src.components.organisms.Faq.delivery.content3.boldInfo":
    "online kalkulačce",
  "src.components.organisms.Faq.delivery.content4.bold": "krabičkové diety",
  "src.components.organisms.Faq.delivery.content6.bold": "krabičkovou dietu",
  "src.components.organisms.Faq.delivery.content7.bold": "krabičkové diety",
  "src.components.organisms.Faq.delivery.content9.bold": "krabičkové diety",
  "src.components.organisms.Faq.consumption.content1.bold": "krabičkové diety",
  "src.components.organisms.Faq.consumption.content2.bold": "krabičkové diety",
  "src.components.organisms.Faq.consumption.content3.bold": "krabičkové diety",
  "src.components.organisms.Faq.client.subTitle1":
    "Mohu změnit své jídelníčky, pokud se změní mé potřeby?",
  "src.components.organisms.Faq.client.content1":
    "Ano, naše krabičková dieta je velmi flexibilní. Pokud potřebujete změnit své jídelníčky nebo upravit velikost porcí, můžete to snadno provést přes náš zákaznický portál nebo kontaktováním naší zákaznické podpory.",
  "src.components.organisms.Faq.client.subTitle2":
    "Jaké možnosti přizpůsobení mé krabičkové diety nabízíte?",
  "src.components.organisms.Faq.client.content2":
    "Umožňujeme vám přizpůsobit si menu podle vašich preferencí, alergií a dalších požadavků. Můžete si zvolit počet chodů, vynechat určité ingredience nebo upravit porce dle vaší potřeby.",
  "src.components.organisms.Faq.client.subTitle3":
    "Jak si dát pauzu v krabičkové dietě?",
  "src.components.organisms.Faq.client.content3":
    "Pauzu si můžete dát kdykoliv, nestojí to nic, stačí jen zavolat {info} nebo napsat e-mail na náš zákaznický servis info@nutritionpro.cz.",
  "src.components.organisms.Faq.client.content3.bold": "226 288 200",
  "src.components.organisms.Faq.client.subTitle4":
    "Jak mohu kontaktovat zákaznickou podporu, pokud mám problém nebo dotaz?",
  "src.components.organisms.Faq.client.content4":
    "Naše zákaznická podpora je vám k dispozici každý pracovní den od 08:00-18:00. Můžete nás kontaktovat telefonicky {info}, e-mailem info@nutritionpro.cz, nebo prostřednictvím našeho zákaznického portálu, kde se snažíme odpovědět co nejrychleji.",
  "src.components.organisms.Faq.client.content4.bold": "226 288 200",
  "src.components.organisms.Faq.client.subTitle5":
    "Jak se vyrovnáváte s připomínkami a stížnostmi?",
  "src.components.organisms.Faq.client.content5":
    "Vaše spokojenost je pro nás prioritou. Každou připomínku nebo stížnost řešíme individuálně a snažíme se najít řešení, které vám vyhovuje.",
  "src.components.organisms.Faq.delivery.prague": "celé Praze a okolí",
  "src.components.organisms.Faq.delivery.chech": "středních Čechách",
  "src.components.organisms.Faq.delivery.kladno": "Kladně",
  "src.components.organisms.Faq.delivery.plzen": "Plzni",
  "src.components.organisms.Faq.delivery.liberec": "Liberci",
  "src.components.organisms.Faq.delivery.mlda": "Mladé Boleslavi",
  "src.components.organisms.Faq.delivery.jablonec": "Jablonci nad Nisou",
  "src.components.organisms.Faq.delivery.paradubice": "Pardubicích",
  "src.components.organisms.Faq.delivery.hradec": "Hradci Králové",
  "src.components.atoms.RealMenuCard.kcal": "kcal",
  "src.components.atoms.RealMenuCard.proteins": "Bílkoviny",
  "src.components.atoms.RealMenuCard.carbohydrates": "Sacharidy",
  "src.components.atoms.RealMenuCard.fats": "Tuky",
  "src.components.organisms.OnlineOrderThiredStep.emptyTimeSlot":
    "Jídlo doručujeme od 16:00 do 19:00 v úterý, čtvrtek a neděli.",
  "src.component.molecules.ChangeWeekMenu.next": "Příští týden",
  "src.component.molecules.ChangeWeekMenu.prev": "Předchozí týden",
  "src.components.organisms.FaqPraha.menu.content1.bold":
    "krabičková dieta Praha",
  "src.components.organisms.FaqPraha.menu.content1.boldInfo":
    "krabičková dieta",
  "src.components.organisms.FaqPraha.menu.content1":
    "Naše {info} se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše {boldInfo} je navržena tak, aby odpovídala vašim individuálním potřebám a cílům, což je něco, co běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.FaqPraha.menu.content2":
    "Jídelníček pro naši {info} je sestavován speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že dosáhnete požadovaných výsledků.",
  "src.components.organisms.FaqPraha.menu.content2.bold":
    "krabičkovou dietu Praha",
  "src.components.organisms.FaqPraha.menu.content3.boldInfo":
    "krabičkovou dietu Praha.",
  "src.components.organisms.FaqPraha.menu.content3.bold": "krabičková dieta",
  "src.components.organisms.FaqPraha.menu.content3":
    "Naše {info} je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, takže se naše jídla nikdy nepřejí. Podívejte se na ukázku jídelníčku, který nabízíme pro {boldInfo}",
  "src.components.organisms.FaqPraha.materials.content1":
    "Pro naši {info} používáme pouze kvalitní a čerstvé suroviny od prověřených českých dodavatelů. Kvalita a čerstvost surovin je pro nás základem zdravého stravování.",
  "src.components.organisms.FaqPraha.materials.content1.bold":
    "krabičkovou dietu Praha",
  "src.components.organisms.FaqPraha.materials.content2":
    "Ano, všechna jídla v rámci naší {info} jsou připravována z čerstvých surovin, které kupujeme v den přípravy. Po uvaření jsou jídla šokově zchlazena a hermeticky zabalena, což zajišťuje jejich maximální čerstvost.",
  "src.components.organisms.FaqPraha.materials.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.materials.content3":
    "Příprava jídel pro naši {info} probíhá v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek, a ve čtvrtek na pátek a sobotu.",
  "src.components.organisms.FaqPraha.materials.content3.bold":
    "krabičkovou dietu",
  "src.components.organisms.FaqPraha.materials.content4":
    "Ano, naše {info} zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",
  "src.components.organisms.FaqPraha.materials.content4.bold":
    "krabičková dieta Praha",
  "src.components.organisms.FaqPraha.delivery.content1":
    "Jakékoliv změny objednávky v rámci naší {info}, včetně pozastavení dovozu, je nutné nahlásit nejpozději do 11:00 tři pracovní dny před plánovaným doručením. Tato pravidla platí, protože pro každé vaření nakupujeme čerstvé suroviny na přesný počet zákazníků.",
  "src.components.organisms.FaqPraha.delivery.content1.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content2":
    "Pokud se vaše objednávka {info} vztahuje na dva dny, je možné zrušit pouze celou dvoudenní objednávku. Zrušení pouze jednoho dne není možné.",
  "src.components.organisms.FaqPraha.delivery.content3.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content3":
    "Cena naší {info} je stanovena individuálně podle vašeho osobního stravovacího plánu a denního kalorického příjmu. Cena se může lišit v závislosti na vašich cílech, ať už se jedná o hubnutí, nabírání svalové hmoty nebo udržování váhy. Kalkulaci ceny si můžete nechat vypočítat v naší online kalkulačce.",
  "src.components.organisms.FaqPraha.delivery.content4":
    "Po dokončení objednávky naší {info} vám zašleme fakturu na váš e-mail. Můžete také zvolit platbu kartou.",
  "src.components.organisms.FaqPraha.delivery.content4.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content6":
    "Naši {info} rozvážíme po {prague}, {chech} , {kladno}, {plzen}, {liberec}, {mlda}, {jablonec}, {paradubic} a {hradec}.",
  "src.components.organisms.FaqPraha.delivery.content6.bold":
    "krabičkovou dietu Praha",
  "src.components.organisms.FaqPraha.delivery.content7":
    "Jídla z naší {info} doručujeme obden – v úterý, čtvrtek a neděli, vždy mezi 16:00 a 22:00 hodinou.",
  "src.components.organisms.FaqPraha.delivery.content7.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content8":
    "Můžete si vybrat konkrétní 2hodinové okno pro doručení (17:00 - 22:00). Přesný čas dovozu bude upřesněn s řidičem v den dovozu.",
  "src.components.organisms.FaqPraha.delivery.content9":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 12:00.",
  "src.components.organisms.FaqPraha.delivery.content9.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.consumption.content1":
    "Některá jídla z naší {info} jsou určena k ohřevu, jiná ke konzumaci za studena. Pro lepší chuť však doporučujeme jídla ohřát. Informace o tom, které jídlo je třeba ohřát, zjistíte po načtení čárového kódu na krabičce.",
  "src.components.organisms.FaqPraha.consumption.content1.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.consumption.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.consumption.content2":
    "Pokud ohříváte jídla z naší {info} v mikrovlnné troubě, doporučujeme propíchnout dírky do folie. Čas ohřevu se liší podle druhu jídla a velikosti porce, ale doporučujeme použít střední výkon (600–700 W) pro rovnoměrné prohřátí.",
  "src.components.organisms.FaqPraha.consumption.content3":
    "Jídla z naší {info} doporučujeme skladovat v lednici, ale můžete je vzít s sebou i do práce nebo na cesty. Jídla vydrží několik hodin mimo lednici bez ztráty chuti nebo kvality.",
  "src.components.organisms.FaqPraha.consumption.content3.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.client.content1":
    "Ano, naše {info} je velmi flexibilní. Pokud potřebujete změnit své jídelníčky nebo upravit velikost porcí, můžete to snadno provést přes náš zákaznický portál nebo kontaktováním naší zákaznické podpory.",
  "src.components.organisms.FaqPraha.client.content1.bold":
    "krabičková dieta Praha",
  "src.components.organisms.FaqPraha.client.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.client.content2":
    "Umožňujeme vám přizpůsobit si menu podle vašich preferencí, alergií a dalších požadavků. Můžete si zvolit počet chodů, vynechat určité ingredience nebo upravit porce dle vaší potřeby v rámci naší {info}.",
  "src.components.organisms.FaqPraha.client.content3":
    "Pauzu si můžete dát kdykoliv, nestojí to nic, stačí jen zavolat na {info} nebo napsat e-mail na náš zákaznický servis {boldInfo}.",
  "src.components.organisms.FaqPraha.client.content3.bold": " 226 288 200",
  "src.components.organisms.FaqPraha.client.content3.boldInfo":
    "info@nutritionpro.cz.",
  "src.components.organisms.FaqPraha.client.content4":
    "Naše zákaznická podpora je vám k dispozici každý pracovní den od 08:00-18:00. Můžete nás kontaktovat telefonicky {info}, e-mailem {boldInfo}, nebo prostřednictvím našeho zákaznického portálu, kde se snažíme odpovědět co",
  "src.components.organisms.FaqPraha.client.content4.bold": "226 288 200",
  "src.components.organisms.FaqPraha.client.content4.boldInfo":
    "info@nutritionpro.cz",
  "src.components.moelecules.BenefitLabels.text3.praha":
    "Kalorie přes {head}, ne omáčky.",
  "src.components.moelecules.BenefitLabels.text4.praha":
    "Pauza {head}, stačí zavolat.",
  "src.components.moelecules.BenefitLabels.text1.praha":
    "Výběr ze {head} u každého chodu podle chuti",
  "src.components.moelecules.BenefitLabels.text2.praha":
    "Rozvážíme krabickou dietu v Praze ve {head}, nebo na odběrné místa.",

  "src.components.organisms.ProgramBlock.titleSecond.praha":
    " jeden z našich programů",
  "src.components.organisms.ProgramBlock.description.praha":
    "Užij si pestrou chuťovou paletu bez omezení. V rámci všech programů máš nyní možnost libovolné chody vyměnit.",
  "src.components.organisms.MenuBlock.titleFirst.praha": "Jaká jídla",
  "src.components.organisms.MenuBlock.titleSecond.praha": " tě s námi čekají?",
  "src.components.organisms.ComplateMenu.titleFirst.praha":
    "Nezávazně vyzkoušej naši",
  "src.components.organisms.ComplateMenu.titleSecond.praha":
    "naši krabičkovou dietu na 2 dny",
  "src.components.organisms.ComplateMenu.description.praha":
    "Zanech nám na sebe kontakt, v pracovní době se ti do dvou hodin ozveme a během 10 minut vše individuálně domluvíme.",
  "src.components.organisms.ReviewBlock.description.praha":
    " Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.titleFirst.praha": "Proč NutritionPro - ",
  "src.components.moelecules.BenefitCityLabels.text3":
    "Kalorie přes bílkoviny, ne omáčky.",
  "src.components.moelecules.BenefitCityLabels.text4":
    "Pauza kdykoliv zdarma, stačí  zavolat.",
  "src.components.moelecules.BenefitCityLabels.text1":
    "Výběr ze {head} u každého chodu podle chuti.",
  "src.components.moelecules.BenefitCityLabels.subText2": "4 jidel",
  "src.components.moelecules.BenefitCityLabels.text2":
    "Rozvozy ráno i večer, domů nebo na výdejnu.",
  "src.components.organisms.FaqPraha.titleFirst": "Často ",
  "src.components.organisms.FaqPraha.titleSecond": "kladené dotazy",
  "src.components.organisms.HowWorks.daily.praha":
    "Nastavíme tvůj denní příjem podle tvých cílů",
  "src.components.organisms.HowWorks.algorithm.praha":
    "Speciální algoritmus sestaví jídelníček na míru",
  "src.components.organisms.HowWorks.cooking.praha": "Jídlo uvaříme",
  "src.components.organisms.HowWorks.delivery.praha":
    "Dovezeme ti domů či do práce",
  "src.components.organisms.HowWorks.goal.praha":
    " Následně dohlédneme na to, abys svého cíle dosáhl | Krabičková dieta Praha",
  "src.components.organisms.HowWorks.titleFirst.praha": "Jak funguje naše ",
  "src.components.organisms.HowWorks.titleSecond.praha":
    "krabičková dieta v Praze?",

  "src.components.organisms.HowCook.tab3.descripton4.praha":
    "Zároveň si můžeš mezi městy i měnit místo dovozu, tedy pokud tě čeká pracovní cesta, či návštěva příbuzných, krabičky ti doručíme kam bude potřeba.",
  "src.components.organisms.HowCook.tab3.description3.praha":
    "Odebírat můžeš na jednom z našich 10 odběrných míst, pohodlně umístěných na frekventovaných místech jako jsou fitness centra. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.tab3.description2.praha":
    "Místo i čas dovozu si ještě ten samý den můžeš změnit, případně si nechat dovézt na odběrné místo. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.tab3.descritpion1.praha":
    "V Praze vozíme v 2 hodinových oknech, které si sám navolíš, tedy nemusíš každý den čekat, jak to zrovna vyjde kurýrovi.",
  "src.components.organisms.HowCook.tab2.descripton4.praha":
    " V jídelníčku máme z každé kuchyně něco, od lasagních po butter chicken, ale neboj, pokud ti exotika nevyhovuje, vždy si můžeš jídlo nahradit, třeba zdravým gulášem. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.tab2.description3.praha":
    "Složení jídel je kontrolováno naší nutriční specialistkou a dbáme na to, aby kalorie v jídle pocházeli z těch nejkvalitnějších zdrojů, bez použití umělých sladidel.",
  "src.components.organisms.HowCook.tab2.description2.praha":
    "Po celou dobu od výroby po doručení měříme zachování teplotního řetězce pro maximální kvalitu a čerstvost jídla při dodání.",
  "src.components.organisms.HowCook.tab2.descritpion1.praha":
    " Krabickovu Diety Praha připravujeme v nejmodernějších kuchyních pod kontrolou našeho technologa a kvalitáře, dle nejvyšších hygienických standardů.",
  "src.components.organisms.HowCook.tab1.descripton4.praha":
    "K jídlu si můžeš také dokoupit vitamínové balíčky v pohodlném jednodenním balení.",
  "src.components.organisms.HowCook.tab1.description3.praha":
    "Kalorie si můžeš libovolně volit od 800-2600 kcal s přesností na 100 kcal a počet chodů od 2 do 5 s možností dokoupit extra porci.",
  "src.components.organisms.HowCook.tab1.description2.praha":
    "Vše si navolíš jednoduše v našem intuitivním uživatelském prostředí, kde zároveň vidíš i jak změna ovlivní rozložení makronutrientů.",
  "src.components.organisms.HowCook.tab1.descritpion1.praha":
    "Máš chuť na sladkou, nebo slanou snídani? Dáš přednost lehkému salátu před teplou večeří? U nás je to na tobě!",

  "src.components.organisms.HowCook.tab1.title1.praha":
    "Vybírej až ze 4 jídel na každý chod",
  "src.components.organisms.HowCook.tab1.title2.praha":
    "Navol si jídelníček v klientské zóně",

  "src.components.organisms.HowCook.tab1.title3.praha":
    "Uzpůsob si příjem dle sebe",
  "src.components.organisms.HowCook.tab1.title4.praha":
    " Doplň si Krabickovu Diety Praha o extra vitamíny",

  "src.components.organisms.HowCook.tab2.title1.praha":
    "Neboj se o kvalitu přípravy",

  "src.components.organisms.HowCook.tab2.title2.praha":
    "Užij si maximální čerstvost",
  "src.components.organisms.HowCook.tab2.title3.praha":
    "Jez jen ty nejlepší kalorie",
  "src.components.organisms.HowCook.tab2.title4.praha":
    "Vyzkoušej rozmanitost jídel",
  "src.components.organisms.HowCook.tab3.title1.praha":
    "Krabičková dieta Praha - Vyber si kdy ti budeme vozit",

  "src.components.organisms.HowCook.tab3.title2.praha":
    "Krabičková dieta Praha - Flexibilně si změň místo a čas",
  "src.components.organisms.HowCook.tab3.title3.praha":
    "Krabičková dieta Praha - Využij jedno z našich odběrných míst",

  "src.components.organisms.HowCook.tab3.title4.praha":
    "Krabičková dieta Praha - Nech si přivézt do více než 10 měst v ČR",
  "src.components.molecules.FirstStepTabs.tab1": "Standardní program",
  "src.components.molecules.FirstStepTabs.tab2": "Demo program",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDayDemo":
    "Celková cena",
  "src.components.molecules.OnlineConfirmCard.priceDemo": "{price} Kč",
  "src.components.atoms.PricePerDay.titleDemo": "Celková cena",
};
